import React, { useContext, useMemo, useState } from "react"
import { TranslatorContext } from "../../context/Translator"
import { Link } from "react-router-dom"
import { Row, Col, Dropdown } from "react-bootstrap"
import { PaginationComponent } from "../../components"
import PageLayout from "../../layouts/PageLayout"
import { useFundmanagersList } from "../../lib/actions"
import DynamicTableComponent from "../../components/tables/DynamicTableComponent"

export default function FundManagerListScreen() {
  const { t, c, n } = useContext(TranslatorContext)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(8)
  const { isLoading, isError, data } = useFundmanagersList(page, limit)

  const IdCellRenderer = ({ index }) => {
    return index + 1
  }

  const userColumns = useMemo(
    () => [
      { Header: "ID", accessor: "id", Cell: IdCellRenderer },
      { Header: "Name", accessor: "name" },
      { Header: "Description", accessor: "description" },
      { Header: "Deposit", accessor: "minimunDeposit" },
      { Header: "Performance Fee", accessor: "performanceFee" },
      { Header: "Platfrom Fee", accessor: "platfromFee", Cell: PlatfromFeeCom },
    ],
    []
  )

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div className="mc-card">
            <div className="mc-breadcrumb">
              <h3 className="mc-breadcrumb-title">{t("Leaderboard")}</h3>
              <ul className="mc-breadcrumb-list">
                <li className="mc-breadcrumb-item">
                  <Link to="/dashboard" className="mc-breadcrumb-link">
                    {t("home")}
                  </Link>
                </li>
                <li className="mc-breadcrumb-item">
                  <Link to="/leader-board" className="mc-breadcrumb-link">
                    {t("leader board")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Col>

        <FundManagerRow />

        <Col xl={12}>
          <div className="mc-card">
            <br />
            lore
            <br />
            <DynamicTableComponent
              isLoading={isLoading}
              isError={isError}
              columns={userColumns}
              data={data ? data?.data?.fundmanager : []}
            />
            {!isLoading &&
              !isError &&
              data?.data?.pagination.totalItems > limit && (
                <PaginationComponent
                  totalItems={data?.data?.pagination.totalItems}
                  totalPages={data?.data?.pagination.totalPages}
                  currentPage={page}
                  onPageChange={(newPage) => setPage(newPage)}
                  limit={limit}
                />
              )}
          </div>
        </Col>
      </Row>
    </PageLayout>
  )
}

const PlatfromFeeCom = ({ row }) => {
  return <p>{row?.platfromFee ?? "NA"}</p>
}

const FundManagerRow = () => {
  return (
    <>
      <Row>
        <Col sm={12}>
          <div className="mc-card">
            <Row className="align-items-center h-100">
              <Col lg={3}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="mc-round-avatar xs mr-4">
                    <img
                      src={"https://ui-avatars.com/api/?name=TEST"}
                      alt="avatar"
                    />
                  </div>
                  &emsp;
                  <div>
                    <div>Test</div>
                    <small style={{ fontSize: "10px", lineHeight: 0.8 }}>
                      Hey this is the description Hey this is the description
                      Hey this is the description Hey this is the description
                    </small>
                  </div>
                </div>
              </Col>

              <Col lg={6}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    textAlign: "center",
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                >
                  <div>
                    <div
                      style={{
                        textTransform: "uppercase",
                      }}
                    >
                      Growth
                    </div>{" "}
                    24%
                  </div>

                  <div>
                    <div
                      style={{
                        textTransform: "uppercase",
                      }}
                    >
                      Total Profits
                    </div>{" "}
                    24.00
                  </div>

                  <div>
                    <div
                      style={{
                        textTransform: "uppercase",
                      }}
                    >
                      Total Trades
                    </div>{" "}
                    24
                  </div>
                </div>
              </Col>

              <Col lg={2}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    flexDirection: "column",
                    fontSize: "14px",
                    textTransform: "uppercase",
                  }}
                  className="mx-auto"
                >
                  <div>
                    Minimum Deposit: <b>24 USD</b>
                  </div>

                  <div>
                    Performance Fee: <b>23.0 %</b>
                  </div>
                </div>
              </Col>

              <Col
                lg={1}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button className="mc-btn primary mx-auto">Follow</button>{" "}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  )
}
