import React, { useContext } from "react"
import { TranslatorContext } from "../context/Translator"

const PaginationComponent = ({
  totalItems,
  totalPages,
  currentPage,
  onPageChange,
  limit = 10, // Default limit is set to 10
}) => {
  const { t, n, currentLanguage } = useContext(TranslatorContext)

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1)
    }
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1)
    }
  }

  // Calculate the start and end item numbers
  const startItem = totalItems === 0 ? 0 : (currentPage - 1) * limit + 1
  const endItem = Math.min(currentPage * limit, totalItems)

  return (
    <div className="mc-paginate">
      <p className="mc-paginate-title">
        {t("Showing")}
        <b> {n(startItem)} </b>
        {t("to")}
        <b> {n(endItem)} </b>
        {t("of")}
        <b> {n(totalItems)} </b>
        {t("results")}
      </p>
      <ul className="mc-paginate-list">
        <li
          className={`mc-paginate-item ${currentPage === 1 ? "disabled" : ""}`}
          onClick={handlePreviousPage}
        >
          {currentLanguage?.dir === "ltr" ? (
            <i className="material-icons">chevron_left</i>
          ) : (
            <i className="material-icons">chevron_right</i>
          )}
        </li>
        <li className="mc-paginate-item active">{n(currentPage)}</li>
        <li
          className={`mc-paginate-item ${
            currentPage === totalPages ? "disabled" : ""
          }`}
          onClick={handleNextPage}
        >
          {currentLanguage?.dir === "ltr" ? (
            <i className="material-icons">chevron_right</i>
          ) : (
            <i className="material-icons">chevron_left</i>
          )}
        </li>
      </ul>
    </div>
  )
}

export default PaginationComponent
