import React, { useContext } from "react"
import { TranslatorContext } from "../../../context/Translator"
import { Link, useNavigate } from "react-router-dom"
import { Row, Col, Dropdown } from "react-bootstrap"
import { FloatCardComponent } from "../../../components/cards"
import { PaginationComponent } from "../../../components"
import LabelFieldComponent from "../../../components/fields/LabelFieldComponent"
import InvoiceTableComponent from "../../../components/tables/InvoiceTableComponent"
import PageLayout from "../../../layouts/PageLayout"
import invoices from "../../../assets/data/invoices.json"
import traffics from "../../../assets/data/traffics.json"
import { ButtonComponent } from "../../../components/elements"
import { Button } from "bootstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import {
  CreateFundManagerService,
  getMyMT5AccountsService,
} from "../../../lib/actions"
import { emitErrorNotification, emitNotification } from "../../../lib/helper"
import { useQuery } from "@tanstack/react-query"

export default function CreateFundmanagerScreen() {
  const { t, c } = useContext(TranslatorContext)

  const { isLoading, data } = useQuery({
    queryKey: ["my-mt5-accounts"],
    queryFn: () => getMyMT5AccountsService(),
  })

  const floats = [
    {
      title: "recieved_amount",
      digit: 78593,
      icon: "account_balance",
      variant: "lg green",
    },
    {
      title: "drafts_amount",
      digit: 24950,
      icon: "drafts",
      variant: "lg blue",
    },
    {
      title: "pending_amount",
      digit: 53617,
      icon: "pending",
      variant: "lg purple",
    },
  ]

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div className="mc-card">
            <div className="mc-breadcrumb">
              {JSON.stringify(data?.data?.data)}
              <h3 className="mc-breadcrumb-title">{t("Add FundManager")}</h3>
              <ul className="mc-breadcrumb-list">
                <li className="mc-breadcrumb-item">
                  <Link to="/dashboard" className="mc-breadcrumb-link">
                    {t("home")}
                  </Link>
                </li>

                <li className="mc-breadcrumb-item">
                  <Link to="/my-accounts" className="mc-breadcrumb-link">
                    {t("My Accounts")}
                  </Link>
                </li>
                <li className="mc-breadcrumb-item">
                  <Link
                    to="/my-accounts/add-fundmanager"
                    className="mc-breadcrumb-link"
                  >
                    {t("Add Fundmanager")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Col>
        {/* {floats.map((float, index) => (
          <Col key={index} md={6} lg={4}>
            <FloatCardComponent
              variant={float.variant}
              digit={c(float.digit)}
              title={t(float.title)}
              icon={float.icon}
            />
          </Col>
        ))} */}
        <Col xl={12}>
          <div className="mc-card">
            <div>
              <CreateFundManagerForm
                mt5Accounts={[111890, 111990, 111234, 111990]}
              />
            </div>
          </div>
        </Col>
      </Row>
    </PageLayout>
  )
}

const CreateFMSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  description: Yup.string(),
  login: Yup.number().required("Required"),
  minimunDeposit: Yup.number().min(0).required("Required"),
  performanceFee: Yup.number()
    .min(0)
    .max(50, "Maximum % sharing can't be more than 50%")
    .required("Required"),
})

const CreateFundManagerForm = ({ mt5Accounts }) => {
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      login: undefined,
      minimunDeposit: 100,
      performanceFee: 10,
    },
    validationSchema: CreateFMSchema,
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true)
      try {
        const result = await CreateFundManagerService(values)

        if (result?.data?.status) {
          console.log(result?.data?.data?.fundmanager?.id)

          emitNotification("success", "Funmanager Account Created")
        } else {
          console.log(result?.data?.message)
          emitErrorNotification(result?.data?.message)
        }
      } catch (error) {}

      actions.setSubmitting(false)
    },
  })

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto",
            rowGap: "24px",
            width: "100%",
            maxWidth: "600px",
          }}
        >
          <LabelFieldComponent
            option={mt5Accounts ?? []}
            label={"Select Account"}
            labelDir="label-col"
            fieldSize=" w-100 h-md"
            name="login"
            value={formik?.values?.login}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            errorMessage={
              formik?.touched?.login && formik?.errors?.login
                ? formik?.errors?.login
                : ""
            }
          />

          <LabelFieldComponent
            type="text"
            label={"Avatar Name"}
            labelDir="label-col"
            fieldSize=" w-100 h-md"
            name="name"
            value={formik?.values?.name}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            errorMessage={
              formik?.touched?.name && formik?.errors?.name
                ? formik?.errors?.name
                : ""
            }
          />

          <LabelFieldComponent
            type="text"
            label={"Description"}
            labelDir="label-col"
            fieldSize=" w-100 h-md"
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            name="description"
            value={formik?.values?.description}
            errorMessage={
              formik?.touched?.description && formik?.errors?.description
                ? formik?.errors?.description
                : ""
            }
          />

          <LabelFieldComponent
            type="number"
            label={"Minimum Deposit"}
            labelDir="label-col"
            fieldSize="w-75 h-md"
            placeholder={""}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            name="minimunDeposit"
            value={formik?.values?.minimunDeposit}
            errorMessage={
              formik?.touched?.minimunDeposit && formik?.errors?.minimunDeposit
                ? formik?.errors?.minimunDeposit
                : ""
            }
          />

          <LabelFieldComponent
            type="text"
            label={"Profit Sharing (%)"}
            labelDir="label-col"
            fieldSize="w-75 h-md"
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            name="performanceFee"
            value={formik?.values?.performanceFee}
            errorMessage={
              formik?.touched?.performanceFee && formik?.errors?.performanceFee
                ? formik?.errors?.performanceFee
                : ""
            }
          />

          <div>
            <ButtonComponent
              className="mc-btn primary"
              style={{ height: "32px" }}
              type="submit"
            >
              Create FundManager
            </ButtonComponent>
            &emsp;
            <Link to="/my-accounts">
              <ButtonComponent
                className="mc-btn primary outline"
                style={{ height: "32px" }}
              >
                Cancel
              </ButtonComponent>
            </Link>
          </div>

          <div></div>
        </div>
      </form>
    </>
  )
}
