import React from "react";
import { Spinner } from "react-bootstrap";

function LoadingComponent() {
  return (
    <>
      <div style={{ height: "200px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Spinner />
        </div>
      </div>
    </>
  );
}

export default LoadingComponent;
