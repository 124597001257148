import React, { useContext, useEffect, useState } from "react";
import { TranslatorContext } from "../../context/Translator";
import { ButtonComponent, AnchorComponent } from "../../components/elements";
import IconFieldComponent from "../../components/fields/IconFieldComponent";
import LogoComponent from "../../components/LogoComponent";
import { useFormik } from "formik";
import { useRegistration } from "../../lib/actions";
import getErrorMessage, {
  emitErrorNotification,
  emitNotification,
} from "../../lib/helper";
import { useNavigate } from "react-router-dom";
import { checkTokenExpiry } from "../../lib/tokenUtils";
import { registrationValidationSchema } from "../../lib/validationSchema";
import CountrySelect from "../../components/CountrySelect";

import { RegisterInitialValues as initialValues } from "../../lib/types";

export default function RegisterPage() {
  const navigate = useNavigate();
  const { t } = useContext(TranslatorContext);
  const [loading, setLoading] = useState(false);
  const { mutate: registerMutation } = useRegistration();

  const formik = useFormik({
    initialValues,
    validationSchema: registrationValidationSchema,
    onSubmit: (values) => {
      setLoading(true);
      const { confirm_password, ...filteredData } = values;
      registerMutation(filteredData, {
        onSuccess: (data) => {
          if (
            data.status === true &&
            data.message !== "User Already Exist,Please Use Another Email"
          ) {
            emitNotification("success", "Registration Success");
            navigate("/login");
          } else {
            emitErrorNotification(data.message);
          }
          setLoading(false);
        },
        onError: (error) => {
          emitErrorNotification(getErrorMessage(error));
          setLoading(false);
        },
      });
    },
  });

  useEffect(() => {
    if (checkTokenExpiry()) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <div className="mc-register">
      <div className="mc-register-banner">
        <img
          className="mc-register-banner-pattern"
          src="images/pattern.webp"
          alt="pattern"
        />
        <div className="mc-register-banner-content">
          <h2 className="mc-register-banner-title">
            Best UX/UI fashion ecommerce dashboard & admin panel
          </h2>
          <p className="mc-register-banner-descrip">
            Elit iusto dolore libero recusandae dolor dolores explicabo ullam
            cum facilis aperiam alias odio quam excepturi molestiae omnis
            inventore. Repudiandae officia placeat amet consectetur dicta
            dolorem quo
          </p>
          <AnchorComponent
            to="/dashboard"
            icon="home"
            text={t("go_to_home")}
            className="mc-btn primary"
          />
        </div>
      </div>
      <form className="mc-register-form" onSubmit={formik.handleSubmit}>
        <LogoComponent
          src="images/logo.webp"
          alt="logo"
          href="/ecommerce"
          className="mc-auth-logo"
        />
        <h4 className="mc-auth-title">{t("register_a_new_account")}</h4>
        <div className="mb-3">
          <IconFieldComponent
            icon="account_circle"
            type="text"
            name="firstName"
            classes="h-sm mb-0"
            placeholder={t("enter_your_first_name")}
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.errors.firstName && formik.touched.firstName && (
            <ErrorMsg msg={formik.errors.firstName} />
          )}
        </div>
        <div className="mb-3">
          <IconFieldComponent
            icon="account_circle"
            type="text"
            name="middleName"
            classes="h-sm mb-0"
            placeholder={t("enter_your_middle_name")}
            value={formik.values.middleName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.errors.middleName && formik.touched.middleName && (
            <ErrorMsg msg={formik.errors.middleName} />
          )}
        </div>
        <div className="mb-3">
          <IconFieldComponent
            icon="account_circle"
            type="text"
            name="lastName"
            classes="h-sm mb-0"
            placeholder={t("enter_your_last_name")}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.errors.lastName && formik.touched.lastName && (
            <ErrorMsg msg={formik.errors.lastName} />
          )}
        </div>
        <div className="mb-3">
          <IconFieldComponent
            icon="email"
            type="email"
            name="email"
            classes="h-sm mb-0"
            placeholder={t("enter_your_email")}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.errors.email && formik.touched.email && (
            <ErrorMsg msg={formik.errors.email} />
          )}
        </div>
        <div className="mb-3">
          <IconFieldComponent
            icon="lock"
            type="password"
            name="password"
            classes="h-sm mb-0"
            placeholder={t("enter_your_password")}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            passwordVisible={true}
          />
          {formik.errors.password && formik.touched.password && (
            <ErrorMsg msg={formik.errors.password} />
          )}
        </div>
        <div className="mb-3">
          <IconFieldComponent
            icon="verified_user"
            type="password"
            name="confirm_password"
            classes="h-sm mb-0"
            placeholder={t("confirm_your_password")}
            value={formik.values.confirm_password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            passwordVisible={true}
          />
          {formik.errors.confirm_password &&
            formik.touched.confirm_password && (
              <ErrorMsg msg={formik.errors.confirm_password} />
            )}
        </div>

        <CountrySelect
          value={formik.values.country}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.country}
          touched={formik.touched.country}
        />
        {/* <div className="mc-auth-checkbox">
          <input type="checkbox" id="checkbox" />
          <label htmlFor="checkbox">{t("agree_to_terms")}</label>
        </div> */}
        <ButtonComponent className="mc-auth-btn h-sm" type="submit">
          {loading ? "Loading..." : t("sign_up")}
        </ButtonComponent>
        <div className="mc-auth-divide">
          <span>{t("or")}</span>
        </div>
        <div className="mc-auth-connect">
          <AnchorComponent to="#" className="twitter h-sm">
            <i className="icofont-twitter"></i>
            <span>{t("continue_with_twitter")}</span>
          </AnchorComponent>
          {/* <AnchorComponent to="#" className="facebook h-sm">
            <i className="icofont-facebook"></i>
            <span>{t("continue_with_facebook")}</span>
          </AnchorComponent> */}
        </div>
        {/* <div className="mc-register-navigate">
          <span>{t("already_have_account")}</span>
          <AnchorComponent to="/login">{t("login")}</AnchorComponent>
        </div> */}
      </form>
    </div>
  );
}

const ErrorMsg = ({ msg }) => {
  return <p className="d-flex text-danger">{msg}</p>;
};
