import React, { useContext } from "react"
import { TranslatorContext } from "../../context/Translator"
import { Link } from "react-router-dom"
import { Row, Col, Dropdown } from "react-bootstrap"
import { FloatCardComponent } from "../../components/cards"
import { PaginationComponent } from "../../components"
import LabelFieldComponent from "../../components/fields/LabelFieldComponent"
import InvoiceTableComponent from "../../components/tables/InvoiceTableComponent"
import PageLayout from "../../layouts/PageLayout"
import invoices from "../../assets/data/invoices.json"
import traffics from "../../assets/data/reports.json"
import FundManagerTableComponent from "../../components/tables/FundManagerTableComponent"
import InvestorTableComponent from "../../components/tables/InvestorTableComponent"
import ReportsTableComponent from "../../components/tables/ReportsTableComponent"

export default function CommissionsListScreen() {
  const { t, c, n } = useContext(TranslatorContext)

  const floats = []

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div className="mc-card">
            <div className="mc-breadcrumb">
              <h3 className="mc-breadcrumb-title">{t("commissions")}</h3>
              <ul className="mc-breadcrumb-list">
                <li className="mc-breadcrumb-item">
                  <Link to="/dashboard" className="mc-breadcrumb-link">
                    {t("home")}
                  </Link>
                </li>
                <li className="mc-breadcrumb-item">
                  <Link to="/commissions" className="mc-breadcrumb-link">
                    {t("commissions")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Col>
        {floats.map((float, index) => (
          <Col key={index} md={6} lg={4}>
            <FloatCardComponent
              variant={float.variant}
              digit={n(float.digit)}
              title={t(float.title)}
              icon={float.icon}
            />
          </Col>
        ))}
        <Col xl={12}>
          <div className="mc-card">
            <Row xs={1} sm={2} lg={4}>
              <Col>
                <LabelFieldComponent
                  type="date"
                  label={t("From")}
                  labelDir="label-col"
                  fieldSize="mb-4 w-100 h-md"
                  placeholder={t("id") + " / " + t("name")}
                />
              </Col>

              <Col>
                <LabelFieldComponent
                  type="date"
                  label={t("To")}
                  labelDir="label-col"
                  fieldSize="mb-4 w-100 h-md"
                  placeholder={t("id") + " / " + t("name")}
                />
              </Col>

              <Col>
                <LabelFieldComponent
                  label={t("Type")}
                  option={["all", "performance", "platform"]}
                  labelDir="label-col"
                  fieldSize="mb-4 w-100 h-md"
                />
              </Col>
            </Row>
            <ReportsTableComponent thead={traffics.thead} tbody={[]} />
            <PaginationComponent
              currentPage={1}
              totalItems={0}
              totalPages={1}
            />
          </div>
        </Col>
      </Row>
    </PageLayout>
  )
}
