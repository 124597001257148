import axios from "axios"
import { checkTokenExpiry } from "../lib/tokenUtils"

const api = axios.create({
  baseURL: "https://api.strelasoft.com/copymytrade/v1",
})

api.interceptors.request.use(
  (config) => {
    const token = checkTokenExpiry()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    } else {
      checkTokenExpiry()
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (401 === error.response.status) {
      // localStorage.removeItem(STORAGE.USER)
      // redirectToLogin()
      return Promise.reject(error)
    }
  }
)

export default api
