import React, { useContext, useState } from "react"
import { ButtonComponent } from "../../../components/elements"
import { TranslatorContext } from "../../../context/Translator"
import { UnSubscribeFundManager } from "../../../lib/actions"
import { emitErrorNotification, emitNotification } from "../../../lib/helper"
import { useNavigate } from "react-router-dom"
import ConfirmModal from "../../../components/ConfirmModal"

function UnSubscribeFundmanager({ account }) {
  const navigate = useNavigate()
  const { t, c, n } = useContext(TranslatorContext)
  const [confirmModal, setConfirmModal] = useState(false)

  const onUnSubscribeFundManager = async () => {
    const result = await UnSubscribeFundManager({
      id: account?.id,
    })

    if (result?.data?.status) {
      emitNotification("success", "Fundmanager unsubscribed!")
      navigate("/my-accounts")
    } else {
      emitErrorNotification(result?.data?.message)
    }
  }
  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto",
          rowGap: "0px",
          width: "100%",
          maxWidth: "600px",
          marginTop: "30px",
        }}
      >
        {account?.fundmanager?.name} <br />
        {account?.fundmanager?.description} <br /> <br />
        Minimum Deposit : {account?.fundmanager?.minimunDeposit ?? 0} <br />
        Performance Fees : {account?.fundmanager?.performanceFee ?? 0} % <br />
        <br />
        <div>
          <ButtonComponent
            className="mc-btn primary"
            style={{ height: "32px" }}
            onClick={() => setConfirmModal(true)}
          >
            UnSubscribe
          </ButtonComponent>
          &emsp;{" "}
        </div>
        <div style={{ fontSize: "10px", color: "red", paddingTop: "12px" }}>
          * Please note: this will close all the existing positions
        </div>
      </div>
      <ConfirmModal
        showModal={confirmModal}
        setShowModal={setConfirmModal}
        onConfirm={onUnSubscribeFundManager}
        title="UnSubscribe"
        desc="This Will close all the existing positions"
      />
    </>
  )
}

export default UnSubscribeFundmanager
