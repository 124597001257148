import { useQuery } from "@tanstack/react-query"
import { useFormik } from "formik"
import { values } from "lodash"
import { LabelFieldComponent } from "../../../components/fields"
import { Col, Row, Spinner } from "react-bootstrap"
import { subscribeToInvestor } from "../../../lib/actions"
import { Link, useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { emitErrorNotification, emitNotification } from "../../../lib/helper"

const subscribeInvestorSchema = Yup.object().shape({
  username: Yup.string().trim().required("Required"),
  direction: Yup.string().required("Required"),
  investor: Yup.number("Select Account").required("Required"),
  tradeType: Yup.string().required("Required"),
  type: Yup.string().required("Required"),
  typeValue: Yup.number().required("Required"),
  floatingLoss: Yup.number().required("Required"),
  floatingProfit: Yup.number().required("Required"),
})

const SubscribeToInvestor = ({ fdm, setFdm }) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: "my-mt5-accounts",
    queryFn: () => getMt5Accounts(),
  })
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      username: "",
      direction: "SAME",
      investor: data?.length ? data[0] : undefined,
      tradeType: "BOTH",
      type: "EQUITY",
      typeValue: 100,
      floatingLoss: 0.0,
      floatingProfit: 0.0,
      symbols: [],
    },
    enableReinitialize: true,
    validationSchema: subscribeInvestorSchema,
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true)

      try {
        const result = await subscribeToInvestor({
          ...values,
          fundmanager: fdm?.id,
        })

        if (result?.data?.status) {
          emitNotification("success", result?.data?.message)
          navigate("/my-accounts")
        } else {
          emitErrorNotification(result?.data?.message ?? "Something went wrong")
        }
      } catch (error) {}

      actions.setSubmitting(false)
    },
  })

  if (isLoading) return <></>

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto",
            rowGap: "24px",
            width: "100%",
            maxWidth: "600px",
          }}
        >
          <LabelFieldComponent
            type="text"
            label={"Fund Manager"}
            labelDir="label-col"
            fieldSize=" w-100 h-md"
            value={fdm?.name ?? fdm?.login ?? ""}
            readOnly={true}
          />

          <LabelFieldComponent
            type="text"
            label={"Public Username"}
            labelDir="label-col"
            fieldSize=" w-100 h-md"
            name="username"
            value={formik?.values?.username}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            errorMessage={
              formik?.touched?.username && formik?.errors?.username
                ? formik?.errors?.username
                : ""
            }
          />

          <LabelFieldComponent
            option={data ?? []}
            label={"Login"}
            labelDir="label-col"
            fieldSize=" w-100 h-md"
            name="investor"
            value={formik?.values?.investor}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            errorMessage={
              formik?.touched?.investor && formik?.errors?.investor
                ? formik?.errors?.investor
                : ""
            }
          />

          <Col xl={6}>
            <LabelFieldComponent
              option={["BOTH", "BUY", "SELL"]}
              label={"Type"}
              labelDir="label-col"
              fieldSize=" w-100 h-md"
              name="tradeType"
              value={formik?.values?.tradeType}
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              errorMessage={
                formik?.touched?.tradeType && formik?.errors?.tradeType
                  ? formik?.errors?.tradeType
                  : ""
              }
            />
          </Col>

          <Col xl={6}>
            <LabelFieldComponent
              option={["SAME", "REVERSE"]}
              label={"DIRECTION"}
              labelDir="label-col"
              fieldSize=" w-100 h-md"
              name="direction"
              value={formik?.values?.direction}
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              errorMessage={
                formik?.touched?.direction && formik?.errors?.direction
                  ? formik?.errors?.direction
                  : ""
              }
            />
          </Col>

          <Col xl={6}>
            <LabelFieldComponent
              option={["EQUITY", "BALANCE", "VOLUME"]}
              label={"BASIS"}
              labelDir="label-col"
              fieldSize=" w-100 h-md"
              name="type"
              value={formik?.values?.type}
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              errorMessage={
                formik?.touched?.type && formik?.errors?.type
                  ? formik?.errors?.type
                  : ""
              }
            />
          </Col>

          <Col xl={6}>
            <div className={formik?.values?.type === "VOLUME" ? "" : "d-none"}>
              <LabelFieldComponent
                type="number"
                label={"%"}
                labelDir="label-col"
                fieldSize=" w-100 h-md"
                name="typeValue"
                value={formik?.values?.typeValue}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                errorMessage={
                  formik?.touched?.typeValue && formik?.errors?.typeValue
                    ? formik?.errors?.typeValue
                    : ""
                }
              />
            </div>
          </Col>

          <LabelFieldComponent
            type="text"
            label={"Floating Loss"}
            labelDir="label-col"
            fieldSize=" w-100 h-md"
            name="floatingLoss"
            value={formik?.values?.floatingLoss}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            errorMessage={
              formik?.touched?.floatingLoss && formik?.errors?.floatingLoss
                ? formik?.errors?.floatingLoss
                : ""
            }
          />

          <LabelFieldComponent
            type="text"
            label={"Floating Profit"}
            labelDir="label-col"
            fieldSize=" w-100 h-md"
            name="floatingProfit"
            value={formik?.values?.floatingProfit}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            errorMessage={
              formik?.touched?.floatingProfit && formik?.errors?.floatingProfit
                ? formik?.errors?.floatingProfit
                : ""
            }
          />

          {formik?.isSubmitting ? (
            <Spinner />
          ) : (
            <div>
              <button
                className="mc-btn primary"
                style={{ height: "32px" }}
                type="submit"
              >
                Subscribe
              </button>
              &emsp;
              <button
                className="mc-btn primary outline"
                style={{ height: "32px" }}
                type="button"
                onClick={() => {
                  setFdm(null)
                }}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </form>
    </>
  )
}

export default SubscribeToInvestor

const getMt5Accounts = () => {
  return Promise.resolve([
    getRandomArbitrary(1000, 9999) + getRandomArbitrary(1000, 9999),
    getRandomArbitrary(1000, 9999) + getRandomArbitrary(1000, 9999),
    getRandomArbitrary(1000, 9999) + getRandomArbitrary(1000, 9999),
  ])
}

function getRandomArbitrary(min, max) {
  return Math.ceil(Math.random() * (max - min) + min)
}
