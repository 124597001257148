import { useContext } from "react"
import { Dropdown } from "react-bootstrap"
import { Link, useLocation } from "react-router-dom"
import { TranslatorContext } from "../context/Translator"
import sidenavs from "../assets/data/sidenavs.json"
import _ from "lodash"
import useSession from "../hooks/useSession"

const TopHeaderLayout = () => {
  const { t, n, c, changeLanguage, currentLanguage } =
    useContext(TranslatorContext)

  const { hash, pathname, search } = useLocation()

  const { user, isLoadingUser } = useSession()

  return (
    <>
      <div
        style={{
          padding: "20px",
        }}
      >
        <div className="row">
          <div className="col-12">
            <div className="mc-card">
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Link to="/" className="mc-logo-group">
                    <img
                      src="/images/logo.png"
                      alt="logo"
                      style={{
                        maxHeight: "40px",
                      }}
                    />
                  </Link>

                  <Dropdown className="mc-header-user">
                    <Dropdown.Toggle className="mc-dropdown-toggle">
                      <Link to="#" className="mc-round-avatar xs">
                        <img
                          src={
                            "https://ui-avatars.com/api/?name=" +
                              user?.firstName ??
                            "-" + user?.middleName ??
                            "-" + user?.lastName ??
                            "-"
                          }
                          alt="avatar"
                        />
                      </Link>
                      <div className="mc-duel-text xs">
                        <h3
                          className="mc-duel-text-title"
                          style={{ textTransform: "lowercase" }}
                        >
                          {user?.email ?? "-"}
                        </h3>
                        <p className="mc-duel-text-descrip">
                          {user?.firstName ?? "-"} {user?.middleName ?? "-"}{" "}
                          {user?.lastName ?? "-"}
                        </p>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end" className="mc-dropdown-paper">
                      <Link to="/forgot-password" className="mc-dropdown-menu">
                        <i className="material-icons">privacy_tip</i>
                        <span>{t("reset_password")}</span>
                      </Link>
                      <Link to="/login" className="mc-dropdown-menu">
                        <i className="material-icons">lock</i>
                        <span>{t("logout")}</span>
                      </Link>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <hr />
              <div
                style={{ display: "flex", padding: "8px", overflowY: "auto" }}
              >
                {_.map(sidenavs[0]?.menus ?? [], (s) => (
                  <>
                    <Link to={s?.path} style={{ marginRight: "24px" }}>
                      <div
                        className={`anchorLink ${
                          pathname?.includes(s?.path) ? "anchorLinkActive" : ""
                        }`}
                        style={{
                          display: "inline-flex",

                          cursor: "pointer",
                          textTransform: "capitalize",
                        }}
                      >
                        {s?.text}
                      </div>
                    </Link>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TopHeaderLayout
