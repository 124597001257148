import { create } from "zustand"

const useSession = create((set) => ({
  user: undefined,
  isLoadingUser: true,
  setUser: (_u) => set((state) => ({ ...state, user: _u })),
  setIsLoadingUser: (_isLoading) =>
    set((state) => ({ ...state, isLoading: _isLoading })),
}))

export default useSession
