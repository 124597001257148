import React, { useContext } from "react"
import { TranslatorContext } from "../../../context/Translator"
import { Link, useParams } from "react-router-dom"
import { Row, Col, Dropdown, Tab, Tabs } from "react-bootstrap"
import { FloatCardComponent } from "../../../components/cards"
import { PaginationComponent } from "../../../components"
import LabelFieldComponent from "../../../components/fields/LabelFieldComponent"
import InvoiceTableComponent from "../../../components/tables/InvoiceTableComponent"
import PageLayout from "../../../layouts/PageLayout"
import invoices from "../../../assets/data/invoices.json"
import traffics from "../../../assets/data/fundmanagers.json"
import FundManagerTableComponent from "../../../components/tables/FundManagerTableComponent"
import { ButtonComponent } from "../../../components/elements"
import PositionsTableComponent from "../../../components/tables/PositionsTableComponent"

export default function EditInvestorsListScreen() {
  const { t, c, n } = useContext(TranslatorContext)

  let { id } = useParams()

  const floats = [
    {
      title: "Total Positions",
      digit: traffics.tbody.length - 1,
      icon: "drafts",
      variant: "lg blue",
    },

    {
      title: "Total Profit",
      digit: 30,
      icon: "pending",
      variant: "lg purple",
    },
    {
      title: "Total Fees",
      digit: 5,
      icon: "account_balance",
      variant: "lg green",
    },
  ]

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div className="mc-card">
            <div className="mc-breadcrumb">
              <h3 className="mc-breadcrumb-title">
                {t("Edit Investor " + id)}
              </h3>
              <ul className="mc-breadcrumb-list">
                <li className="mc-breadcrumb-item">
                  <Link to="/investors" className="mc-breadcrumb-link">
                    {t("Investor")}
                  </Link>
                </li>
                <li className="mc-breadcrumb-item">
                  <Link
                    to={`/investors/${id}/edit`}
                    className="mc-breadcrumb-link"
                  >
                    {t("Edit")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Col>
        {floats.map((float, index) => (
          <Col key={index} md={6} lg={4}>
            <FloatCardComponent
              variant={float.variant}
              digit={n(float.digit)}
              title={t(float.title)}
              icon={float.icon}
            />
          </Col>
        ))}
        <Col xl={12}>
          <div className="mc-card">
            <Row xs={1} sm={2} lg={4} style={{ display: "none" }}>
              <Col>
                <LabelFieldComponent
                  type="search"
                  label={t("search_by")}
                  labelDir="label-col"
                  fieldSize="mb-4 w-100 h-md"
                  placeholder={t("id") + " / " + t("name")}
                />
              </Col>

              <Col>
                <LabelFieldComponent
                  type="search"
                  label={t("search_by")}
                  labelDir="label-col"
                  fieldSize="mb-4 w-100 h-md"
                  placeholder={t("email")}
                />
              </Col>

              <Col>
                <LabelFieldComponent
                  label={t("status_by")}
                  option={["active", "inactive", "pending"]}
                  labelDir="label-col"
                  fieldSize="mb-4 w-100 h-md"
                />
              </Col>
              <Col>
                <LabelFieldComponent
                  label={t("show_by")}
                  option={["12 row", "24 row", "36 row"]}
                  labelDir="label-col"
                  fieldSize="mb-4 w-100 h-md"
                />
              </Col>
            </Row>

            <Tabs defaultActiveKey="OpenPositons" id="mc" className="mc-tabs">
              <Tab
                eventKey="OpenPositons"
                title={t("Open Positions")}
                className="mc-tabpane OpenPositons"
                style={{ marginRight: "16px" }}
              >
                <PositionsTableComponent
                  thead={[
                    "position",
                    "login",
                    "symbol",
                    "volume",
                    "action",
                    "price",
                    "profit",
                    "created at",
                  ]}
                  tbody={[
                    {
                      position: (Math.random() * 300000).toFixed(0),
                      login: id,
                      symbol: "xaudusd",
                      volume: "0.05",
                      action: "BUY",
                      price: (Math.random() * 2000).toFixed(2),
                      profit: (Math.random() * 50).toFixed(2),
                      created_at: "06 Jun 2024, 03:05 PM",
                      isActive: true,
                    },
                    {
                      position: (Math.random() * 300000).toFixed(0),
                      login: id,
                      symbol: "xaudusd",
                      volume: "0.05",
                      action: "BUY",
                      price: (Math.random() * 2000).toFixed(2),
                      profit: (Math.random() * 50).toFixed(2),
                      created_at: "06 Jun 2024, 03:05 PM",
                      isActive: true,
                    },
                    {
                      position: (Math.random() * 300000).toFixed(0),
                      login: id,
                      symbol: "xaudusd",
                      volume: "0.05",
                      action: "BUY",
                      price: (Math.random() * 2000).toFixed(2),
                      profit: (Math.random() * 50).toFixed(2),
                      created_at: "06 Jun 2024, 03:05 PM",
                      isActive: true,
                    },
                  ]}
                />
                <PaginationComponent first={1} last={3} />
              </Tab>
              &emsp;
              <Tab
                eventKey="Edit "
                title={t("edit")}
                className="mc-tabpane edit"
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto",
                    rowGap: "0px",
                    width: "100%",
                    maxWidth: "600px",
                  }}
                >
                  <LabelFieldComponent
                    option={["Ankit", "Abhishek", "Aniket", "Suraj"]}
                    label={t("Select User")}
                    labelDir="label-col"
                    fieldSize="mb-4 w-100 h-md"
                  />
                  <LabelFieldComponent
                    option={[111890, 111990, 111234]}
                    label={t("Select Account")}
                    labelDir="label-col"
                    fieldSize="mb-4 w-100 h-md"
                  />

                  <LabelFieldComponent
                    option={[
                      "11879 - PROFIT:35 USD, DIRECTION: BOTH",
                      "11821 - PROFIT:-25 USD, DIRECTION: BOTH",
                      "11678 - PROFIT:15 USD, DIRECTION: BOTH",
                      "11119 - PROFIT:0.5 USD, DIRECTION: BOTH",
                    ]}
                    label={t("Select FUND MANAGER")}
                    labelDir="label-col"
                    fieldSize="mb-4 w-100 h-md"
                  />
                  <LabelFieldComponent
                    option={["BOTH", "BUY", "SELL"]}
                    label={t("Select Direction")}
                    labelDir="label-col"
                    fieldSize="mb-4 w-100 h-md"
                  />

                  <div>
                    <Link to="/investors/11849/edit">
                      <ButtonComponent
                        className="mc-btn primary"
                        style={{ height: "32px" }}
                      >
                        Edit Investor
                      </ButtonComponent>
                    </Link>{" "}
                    &emsp;{" "}
                    <ButtonComponent
                      className="mc-btn"
                      style={{ height: "32px", color: "gray" }}
                    >
                      UnSubscribe
                    </ButtonComponent>
                  </div>
                </div>
              </Tab>{" "}
              &emsp;
              <Tab
                eventKey="fundmanager "
                title={t("fundmanager")}
                className="mc-tabpane profile"
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto",
                    rowGap: "0px",
                    width: "100%",
                    maxWidth: "600px",
                  }}
                >
                  <LabelFieldComponent
                    option={[
                      "11879 - PROFIT:35 USD, DIRECTION: BOTH",
                      "11821 - PROFIT:-25 USD, DIRECTION: BOTH",
                      "11678 - PROFIT:15 USD, DIRECTION: BOTH",
                      "11119 - PROFIT:0.5 USD, DIRECTION: BOTH",
                    ]}
                    label={t("Select FUND MANAGER")}
                    labelDir="label-col"
                    fieldSize="mb-4 w-100 h-md"
                  />

                  <div>
                    <Link to="/investors/11849/edit">
                      <ButtonComponent
                        className="mc-btn primary"
                        style={{ height: "32px" }}
                      >
                        Change Fund Manager
                      </ButtonComponent>
                    </Link>{" "}
                    &emsp;{" "}
                  </div>

                  <br />

                  <div style={{ fontSize: "10px" }}>
                    * Please note: this will close all the existing positions
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </Col>
      </Row>
    </PageLayout>
  )
}
