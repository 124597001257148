import React, { useState, useEffect, useContext } from "react"
import { TranslatorContext } from "../../context/Translator"
import Modal from "react-bootstrap/Modal"
import { AnchorComponent, ButtonComponent } from "../elements"
import { Link } from "react-router-dom"

export default function InvoiceTableComponent({ thead, tbody }) {
  const [alertModal, setAlertModal] = React.useState(false)
  const [data, setData] = useState([])
  const { t } = useContext(TranslatorContext)

  useEffect(() => {
    setData(tbody)
  }, [tbody])

  // const handleCheckbox = (event) => {
  //     const { name, checked } = event.target;

  //     if(name === "allCheck") {
  //         const checkData = data?.map((item)=> {
  //             return { ...item, isChecked: checked };
  //         });
  //         setData(checkData);
  //     }
  //     else {
  //         const checkData = data?.map((item) =>
  //             item.name === name ? {...item, isChecked: checked} : item
  //         );
  //         setData(checkData);
  //     }
  // }

  return (
    <div className="mc-table-responsive">
      <table className="mc-table">
        <thead className="mc-table-head primary">
          <tr>
            {thead.map((item, index) => (
              <th key={index}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody className="mc-table-body even">
          {data?.map((item, index) => (
            <tr key={index}>
              <td> # {item.id}</td>

              <td>{item.name}</td>

              <td>{item.email}</td>

              <td>{item.phone}</td>

              <td>{item.created_at}</td>

              {/* <td>
                <Link to={`/users/${item?.id}`}>
                  <i className="material-icons">visibility</i>
                </Link>
              </td> */}
              {/* <td>
                <div className="mc-table-check">
                  <p>{item.id}</p>
                </div>
              </td>
              <td>
                <div className="mc-table-profile">
                  <img src={item.src} alt={item.alt} />
                  <p>{item.name}</p>
                </div>
              </td>
              <td>{item.email}</td>
              <td>{item.amount}</td>
              <td>
                <p className={`mc-table-badge ${item.status.variant}`}>
                  {item.status.text}
                </p>
              </td>
              <td>{item.date}</td> 
              
              "delete": "delete", "download": "download", "view": "visibility" 
              */}

              <td>
                <div className="mc-table-action">
                  {/* <AnchorComponent
                    title="View"
                    href="#"
                    className="material-icons view"
                  >
                    visibility
                  </AnchorComponent> */}

                  <AnchorComponent
                    title="Download"
                    to={`/users/${item.id}/edit`}
                    className="material-icons download"
                    download
                  >
                    edit
                  </AnchorComponent>

                  <ButtonComponent
                    title="Delete"
                    className="material-icons delete"
                    onClick={() => setAlertModal(true)}
                  >
                    delete
                  </ButtonComponent>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal show={alertModal} onHide={() => setAlertModal(false)}>
        <div className="mc-alert-modal">
          <i className="material-icons">new_releases</i>
          <h3>are your sure!</h3>
          <p>Want to delete this user?</p>
          <Modal.Footer>
            <ButtonComponent
              type="button"
              className="btn btn-secondary"
              onClick={() => setAlertModal(false)}
            >
              {t("close")}
            </ButtonComponent>
            <ButtonComponent
              type="button"
              className="btn btn-danger"
              onClick={() => setAlertModal(false)}
            >
              {t("delete")}
            </ButtonComponent>
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  )
}
