import React, { useEffect } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { checkTokenExpiry } from "./tokenUtils"
import useSession from "../hooks/useSession"
import { getMeService } from "./actions"
import { COOKIES_CONSTANT } from "../constants"
import Cookies from "js-cookie"

const ProtectedRoute = ({ children }) => {
  // const token = checkTokenExpiry();

  // if (!token) {
  //   return <Navigate to="/login" />;
  // }

  const { user, isLoadingUser, setUser, setIsLoadingUser } = useSession()
  const navigate = useNavigate()

  useEffect(() => {
    checkedIfLoggedIn()
  }, [])

  const checkedIfLoggedIn = async () => {
    const token = checkTokenExpiry()

    if (!token) {
      setUser(undefined)
      setIsLoadingUser(false)
      navigate("/login")
    }

    if (user) {
      setIsLoadingUser(false)
      return
    }

    try {
      const result = await getMeService()

      console.log()

      if (result?.data?.status) {
        setUser(result?.data?.data?.me)
        setIsLoadingUser(false)
        return
      }
    } catch (error) {}

    setUser(undefined)
    setIsLoadingUser(false)
    Cookies.remove(COOKIES_CONSTANT.TOKEN)
    navigate("/login")
  }

  return <>{children}</>
}

export default ProtectedRoute
