import React, { useContext } from "react"
import { TranslatorContext } from "../../../context/Translator"
import { Link } from "react-router-dom"
import { Row, Col, Dropdown } from "react-bootstrap"
import { FloatCardComponent } from "../../../components/cards"
import { PaginationComponent } from "../../../components"
import LabelFieldComponent from "../../../components/fields/LabelFieldComponent"
import InvoiceTableComponent from "../../../components/tables/InvoiceTableComponent"
import PageLayout from "../../../layouts/PageLayout"
import invoices from "../../../assets/data/invoices.json"
import traffics from "../../../assets/data/traffics.json"
import { ButtonComponent } from "../../../components/elements"
import { Button } from "bootstrap"

export default function CreateUsersListScreen() {
  const { t, c } = useContext(TranslatorContext)

  const floats = [
    {
      title: "recieved_amount",
      digit: 78593,
      icon: "account_balance",
      variant: "lg green",
    },
    {
      title: "drafts_amount",
      digit: 24950,
      icon: "drafts",
      variant: "lg blue",
    },
    {
      title: "pending_amount",
      digit: 53617,
      icon: "pending",
      variant: "lg purple",
    },
  ]

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div className="mc-card">
            <div className="mc-breadcrumb">
              <h3 className="mc-breadcrumb-title">{t("Create User")}</h3>
              <ul className="mc-breadcrumb-list">
                <li className="mc-breadcrumb-item">
                  <Link to="/dashboard" className="mc-breadcrumb-link">
                    {t("home")}
                  </Link>
                </li>
                <li className="mc-breadcrumb-item">
                  <Link to="/users" className="mc-breadcrumb-link">
                    {t("users")}
                  </Link>
                </li>

                <li className="mc-breadcrumb-item">
                  <Link to="/users/create" className="mc-breadcrumb-link">
                    {t("create user")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Col>
        {/* {floats.map((float, index) => (
          <Col key={index} md={6} lg={4}>
            <FloatCardComponent
              variant={float.variant}
              digit={c(float.digit)}
              title={t(float.title)}
              icon={float.icon}
            />
          </Col>
        ))} */}
        <Col xl={12}>
          <div className="mc-card">
            <div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto",
                  rowGap: "0px",
                  width: "100%",
                  maxWidth: "600px",
                }}
              >
                <LabelFieldComponent
                  type="search"
                  label={t("Name")}
                  labelDir="label-col"
                  fieldSize="mb-4 w-100 h-md"
                />
                <LabelFieldComponent
                  type="search"
                  label={t("Email")}
                  labelDir="label-col"
                  fieldSize="mb-4 w-100 h-md"
                />
                <LabelFieldComponent
                  type="search"
                  label={t("Phone")}
                  labelDir="label-col"
                  fieldSize="mb-4 w-100 h-md"
                />
                <LabelFieldComponent
                  type="search"
                  label={t("Country")}
                  labelDir="label-col"
                  fieldSize="mb-4 w-100 h-md"
                />
                <LabelFieldComponent
                  type="file"
                  label={t("Profile Picture")}
                  labelDir="label-col"
                  fieldSize="mb-4 w-100 h-md"
                />

                <ButtonComponent
                  className="mc-btn primary"
                  style={{ height: "32px" }}
                >
                  Create User
                </ButtonComponent>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </PageLayout>
  )
}
