export const RegisterInitialValues = {
  firstName: "",
  middleName: "",
  lastName: "",
  email: "",
  password: "",
  confirm_password: "",
  country: "IND",
  tenantID: 1,
};

export const LoginInitialValues={
  email:'john.doe@example.com',
  password:"J0hnM!chael2024"
}