import React, { useEffect, useState, useMemo } from "react";
import { Form } from "react-bootstrap";
import api from "../services/api";

const CountrySelect = ({ value, onChange, onBlur, error, touched }) => {
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await api.get("/auth/countries");
        console.log(response);

        setCountries(response.data.data.countries);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching countries", error);
        setLoading(false);
      }
    };

    fetchCountries();
  }, []);

  const options = useMemo(() => {
    return countries?.map((country) => (
      <option key={country.code} value={country.code} label={country.name}>
        {country.name}
      </option>
    ));
  }, [countries]);

  return (
    <div className="mb-4">
      <Form.Select
        id="country"
        name="country"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className="h-sm text-white p-2"
        style={{ backgroundColor: "#071739" }}
        disabled={loading}
      >
        <option value="" label="Select Country" />
        {options}
      </Form.Select>
      {error && touched && <p className="error">{error}</p>}
      {loading && <p>Loading countries...</p>}
    </div>
  );
};

export default CountrySelect;
