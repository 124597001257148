import * as Yup from "yup";

export const registrationValidationSchema = Yup.object({
  firstName: Yup.string()
    .min(2, "Minimum 2 characters")
    .max(15, "Maximum 15 characters")
    .required("Required!"),
  middleName: Yup.string()
    .min(2, "Minimum 2 characters")
    .max(15, "Maximum 15 characters"),
  lastName: Yup.string()
    .min(2, "Minimum 2 characters")
    .max(15, "Maximum 15 characters")
    .required("Required!"),
  email: Yup.string().email("Invalid email format").required("Required!"),
  password: Yup.string().min(8, "Minimum 8 characters").required("Required!"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords do not match")
    .required("Required!"),
  country: Yup.string().required("Required!"),
});

export const loginValidationSchema = Yup.object({
  email: Yup.string().email("Invalid email format").required("Required!"),
  password: Yup.string().required("Required!"),
});
