import { useQuery } from "@tanstack/react-query"
import { useState } from "react"
import { getActiveFundManagersService } from "../../../lib/actions"
import { Col, Row, Modal } from "react-bootstrap"
import LoadingComponent from "../../../components/LoadingComponent"
import { PaginationComponent } from "../../../components"
import _ from "lodash"
import SubscribeToInvestor from "./SubscribeToInvestor"

const ListOfLeaders = () => {
  const [currentPage, setCurrentPage] = useState(1)

  const { data, isLoading, isError } = useQuery({
    queryKey: ["active-fundmanagers" + currentPage, { currentPage }],
    queryFn: () => getActiveFundManagersService(currentPage),
  })

  const [selectedFM, setSelectedFM] = useState(null)

  if (isLoading)
    return (
      <>
        <Row>
          <Col sm={12}>
            <div className="mc-card">
              <LoadingComponent />
            </div>
          </Col>
        </Row>
      </>
    )

  const pagination = data.data?.data?.pagination ?? {}

  if (selectedFM) {
    return (
      <Col sm={12}>
        <div className="mc-card ">
          <Row className="align-items-center h-100">
            <Col lg={6}>
              <h4>Subscribe To a Fund Manager</h4>
              <br />

              <SubscribeToInvestor fdm={selectedFM} setFdm={setSelectedFM} />
            </Col>
          </Row>
        </div>
      </Col>
    )
  }

  return (
    <>
      {_.map(data?.data?.data?.fundmanager, (_fdm) => (
        <FundManagerRow
          key={_fdm?.id}
          fundManager={_fdm}
          onFollow={() => {
            setSelectedFM(_fdm)
          }}
        />
      ))}

      <PaginationComponent
        totalItems={pagination?.totalItems ?? 0}
        totalPages={pagination?.totalPages ?? 1}
        currentPage={currentPage}
      />
    </>
  )
}

export default ListOfLeaders

const FundManagerRow = ({ fundManager, onFollow }) => {
  const statistics = fundManager?.Statistics?.length
    ? fundManager?.Statistics[0]
    : {}

  return (
    <>
      <Col sm={12}>
        <div className="mc-card shadow">
          <Row className="align-items-center h-100">
            <Col lg={3}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="mc-round-avatar xs mr-4">
                  <img
                    src={
                      "https://ui-avatars.com/api/?name=" + fundManager?.name ??
                      ""
                    }
                    alt="avatar"
                  />
                </div>
                &emsp;
                <div>
                  <div>{fundManager?.name ?? ""}</div>
                  <small style={{ fontSize: "10px", lineHeight: 0.8 }}>
                    {fundManager?.description ?? "-"}
                  </small>
                </div>
              </div>
            </Col>

            <Col lg={6}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  textAlign: "center",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                <div>
                  <div
                    style={{
                      textTransform: "uppercase",
                    }}
                  >
                    Growth
                  </div>{" "}
                  {statistics?.growth} %
                </div>

                <div>
                  <div
                    style={{
                      textTransform: "uppercase",
                    }}
                  >
                    Total Profits
                  </div>{" "}
                  {statistics?.profit}
                </div>

                <div>
                  <div
                    style={{
                      textTransform: "uppercase",
                    }}
                  >
                    Total Trades
                  </div>{" "}
                  {statistics?.trades}
                </div>
              </div>
            </Col>

            <Col lg={2}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexDirection: "column",
                  fontSize: "14px",
                  textTransform: "uppercase",
                }}
                className="mx-auto"
              >
                <div>
                  Minimum Deposit: <b>{fundManager?.minimunDeposit} USD</b>
                </div>

                <div>
                  Performance Fee: <b>{fundManager?.performanceFee}%</b>
                </div>
              </div>
            </Col>

            <Col
              lg={1}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button onClick={onFollow} className="mc-btn primary mx-auto">
                Follow
              </button>{" "}
            </Col>
          </Row>
        </div>
      </Col>
    </>
  )
}
