import { toast } from "react-toastify"

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export function emitNotification(variant, msg, autoClose = 5000) {
  toast[variant](msg, {
    containerId: "solid",
    autoClose,
    position: "top-right",
  })
}

export function emitErrorNotification(
  msg = "Something went wrong, Please try again!",
  autoClose = 5000
) {
  toast.error(msg, { containerId: "solid", autoClose, position: "top-right" })
}

export default function getErrorMessage(error) {
  let errorMessage = "Failed to do something exceptional"
  if (error) {
    errorMessage = Array.isArray(error?.response?.data?.message)
      ? error?.response?.data?.message?.[0]
      : error?.response?.data?.message
  }
  return errorMessage || "Something went wrong"
}

export function formatDate(dateString) {
  const date = new Date(dateString)
  const day = date.getUTCDate().toString().padStart(2, "0")
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]
  const month = monthNames[date.getUTCMonth()]
  const year = date.getUTCFullYear()
  let hours = date.getUTCHours()
  const minutes = date.getUTCMinutes().toString().padStart(2, "0")
  const ampm = hours >= 12 ? "PM" : "AM"
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  const formattedTime = `${hours
    .toString()
    .padStart(2, "0")}:${minutes} ${ampm}`

  return `${day} ${month} ${year}, ${formattedTime}`
}
