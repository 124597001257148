import React, { useContext } from "react"
import { LabelFieldComponent } from "../../../components/fields"
import { ButtonComponent } from "../../../components/elements"
import { TranslatorContext } from "../../../context/Translator"
import { UnSubscribeFundManager } from "../../../lib/actions"
import { emitErrorNotification, emitNotification } from "../../../lib/helper"
import { useNavigate } from "react-router-dom"
import { Col, Row } from "react-bootstrap"

function EditInvestor({ account }) {
  const navigate = useNavigate()
  const { t, c, n } = useContext(TranslatorContext)

  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto",
          rowGap: "0px",
          width: "100%",
          maxWidth: "600px",
        }}
      >
        <LabelFieldComponent
          type="text"
          label={"Login"}
          labelDir="label-col"
          fieldSize="mb-4 w-100 h-md"
          value={account?.login ?? ""}
          readOnly={true}
        />

        <Row>
          <Col xl={6}>
            <LabelFieldComponent
              option={["BOTH", "BUY", "SELL"]}
              label={t("Type")}
              labelDir="label-col"
              fieldSize="mb-4 w-100 h-md"
              value={account?.tradeType}
            />
          </Col>

          <Col xl={6}>
            <LabelFieldComponent
              option={["SAME", "REVERSE"]}
              label={t("DIRECTION")}
              labelDir="label-col"
              fieldSize="mb-4 w-100 h-md"
              value={account?.direction}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={6}>
            <LabelFieldComponent
              option={["EQUITY", "BALANCE", "VOLUME"]}
              label={t("BASIS")}
              labelDir="label-col"
              fieldSize="mb-4 w-100 h-md"
              value={account?.type}
            />
          </Col>

          <Col xl={6}>
            {/* <LabelFieldComponent
              type="number"
              label={t("Select Account")}
              labelDir="label-col"
              fieldSize="mb-4 w-100 h-md"
            /> */}
          </Col>
        </Row>

        <LabelFieldComponent
          type="text"
          label={"Floating Loss"}
          labelDir="label-col"
          fieldSize="mb-4 w-100 h-md"
          value={account?.floatingLoss ?? ""}
          readOnly={true}
        />

        <LabelFieldComponent
          type="text"
          label={"Floating Profit"}
          labelDir="label-col"
          fieldSize="mb-4 w-100 h-md"
          value={account?.floatingProfit ?? ""}
          readOnly={true}
        />

        <div>
          <ButtonComponent
            className="mc-btn primary"
            style={{ height: "32px" }}
          >
            Update
          </ButtonComponent>
        </div>
      </div>
    </>
  )
}

export default EditInvestor
