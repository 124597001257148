import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import api from "../services/api"
import getErrorMessage, {
  emitErrorNotification,
  emitNotification,
} from "./helper"

const apiTimeInterval = 5000

//************Login-start*********************//

const login = async (data) => {
  const response = await api.post("/auth/login", data)
  return response.data
}
//************Login-end*********************//

//************registration-start*********************//

const registration = async (data) => {
  const response = await api.post("/auth/registration", data)
  return response.data
}
//************registration-end*********************//

//************Get-MyAccounts-Start*********************//
const getMyAccounts = async ({ queryKey }) => {
  const [, { page, limit }] = queryKey
  const response = await api.get(
    `/users/myaccounts?page=${page}&limit=${limit}`
  )
  return response.data
}

const getMyAccountsDetails = async ({ queryKey }) => {
  const [, { id }] = queryKey
  const response = await api.get(`acc/${id}`)
  return response.data
}

//************Get-MyAccounts-Start*********************//

//************Get-Users-Start*********************//

const getUsers = async ({ queryKey }) => {
  const [, { page, limit }] = queryKey
  const response = await api.get(`/users?page=${page}&limit=${limit}`)
  return response.data
}

const editUser = async (data) => {
  const response = await api.put(`/users/${data?.id}`, data)
  return response.data
}

const deleteUser = async (id) => {
  const response = await api.delete(`/users/${id}`)
  return response.data
}
//************Get-Users-End*********************//

//************Fundmanagers-Start*********************//
const getFundmanagers = async ({ queryKey }) => {
  const [, { page, limit }] = queryKey
  const response = await api.get(`/fundmanager?page=${page}&limit=${limit}`)
  return response.data
}
//************Fundmanagers-End*********************//

// Actions
export const useLogin = () => {
  return useMutation({
    mutationFn: login,
  })
}

export const useRegistration = () => {
  return useMutation({
    mutationFn: registration,
  })
}

export const useGetMyAccountsList = (page, limit) => {
  return useQuery({
    queryKey: ["MyAccounts", { page, limit }],
    queryFn: getMyAccounts,
  })
}

export const useGetMyAccountsDetails = (id) => {
  return useQuery({
    queryKey: ["MyAccountsDetails-" + id, { id }],
    queryFn: getMyAccountsDetails,
  })
}

export const useGetUsersList = (page, limit) => {
  return useQuery({
    queryKey: ["UsersList", { page, limit }],
    queryFn: getUsers,
  })
}

export const useEditUser = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: editUser,
    onSuccess: (data) => {
      console.log("Update successful")
      queryClient.invalidateQueries(["UsersList"])
    },
    onError: (error) => {
      console.error("Update failed:", error)
    },
  })
}

export const useDeleteUser = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: deleteUser,
    onSuccess: (data) => {
      if (data.status) {
        emitNotification("success", "User Deleted successfully")
        queryClient.invalidateQueries(["UsersList"])
      }
      emitErrorNotification(data.message)
    },
    onError: (error) => {
      emitErrorNotification(getErrorMessage(error))
    },
  })
}

export const useFundmanagersList = (page, limit) => {
  return useQuery({
    queryKey: ["Fundmanagers-list", { page, limit }],
    queryFn: getFundmanagers,
  })
}

export const CreateFundManagerService = (data) => {
  return api.post("/fundmanager", data)
}
export const EditFundManagerService = (data) => {
  return api.put("/fundmanager", data)
}

export const UnSubscribeFundManager = (data) => {
  return api.post("/investor/unsubscribe", data)
}

export const getMeService = () => {
  return api.get("/me")
}

export const getActiveFundManagersService = (page) => {
  return api.get("/fundmanager?page" + page)
}

export const subscribeToInvestor = (data) => {
  return api.post("/investor/subscribe", data)
}

export const getMyMT5AccountsService = () => {
  return api.get("/mt5Accounts")
}
