import React, { useContext, useEffect, useState } from "react";
import { TranslatorContext } from "../../../context/Translator";
import { Link } from "react-router-dom";
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import LabelFieldComponent from "../../../components/fields/LabelFieldComponent";
import PageLayout from "../../../layouts/PageLayout";
import { ButtonComponent } from "../../../components/elements";

import {
  RegisterInitialValues,
} from "../../../lib/types";
import { useFormik } from "formik";
import getErrorMessage, {
  emitErrorNotification,
  emitNotification,
} from "../../../lib/helper";
import { useEditUser } from "../../../lib/actions";
import { registrationValidationSchema } from "../../../lib/validationSchema";
import { useQuery } from "@tanstack/react-query";
import CountrySelect from "../../../components/CountrySelect";

const fetchUserDetails = async (userId) => {
  // Simulate API call
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        data: {
          firstName: "John",
          middleName: "A.",
          lastName: "Doe",
          email: "john.doe@example.com",
          password: "Nishant@7156",
          confirm_password: "",
          country: "USA",
          tenantID: 1,
        },
      });
    }, 1000);
  });
};

export default function EditUsersListScreen({ userId }) {
  const { t, c } = useContext(TranslatorContext);
  const [loading, setLoading] = useState(false);

  const fetchUserData = async (userId) => {
    const response = await fetchUserDetails(userId);
    return response.data;
  };
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["userDetails", userId],
    queryFn: () => fetchUserData(userId),
    // enabled: !!userId,
  });

  const { mutate: registerMutation } = useEditUser();

  const formik = useFormik({
    initialValues: RegisterInitialValues,
    validationSchema: registrationValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setLoading(true);
      const { confirm_password, ...filteredData } = values;
      // Mock function to simulate a registration mutation
      registerMutation(filteredData, {
        onSuccess: (data) => {
          if (
            data.status === true &&
            data.message !== "User Already Exist,Please Use Another Email"
          ) {
            emitNotification("success", "Registration Success");
          } else {
            emitErrorNotification(data.message);
          }
          setLoading(false);
        },
        onError: (error) => {
          emitErrorNotification(getErrorMessage(error));
          setLoading(false);
        },
      });
    },
  });

  useEffect(() => {
    if (data) {
      console.log(data, "data");

      formik.setValues(data);
    }
  }, [data]);

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Error: {error.message}</p>;

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div className="mc-card">
            <div className="mc-breadcrumb">
              <h3 className="mc-breadcrumb-title">{t("Edit User")}</h3>
              <ul className="mc-breadcrumb-list">
                <li className="mc-breadcrumb-item">
                  <Link to="/dashboard" className="mc-breadcrumb-link">
                    {t("home")}
                  </Link>
                </li>
                <li className="mc-breadcrumb-item">
                  <Link to="/users" className="mc-breadcrumb-link">
                    {t("users")}
                  </Link>
                </li>

                <li className="mc-breadcrumb-item">
                  <Link to="/users/1/edit" className="mc-breadcrumb-link">
                    {t("Edit user")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Col>

        <Col xl={12}>
          <div className="mc-card">
            <div>
              <Tabs defaultActiveKey="profilea" id="mc" className="mc-tabs">
                <Tab
                  eventKey="profilea"
                  title={t("edit_profile")}
                  className="mc-tabpane profile"
                  style={{ marginRight: "16px" }}
                >
                  <br />
                  <Overview formik={formik} />
                </Tab>
              </Tabs>
            </div>
          </div>
        </Col>
      </Row>
    </PageLayout>
  );
}

const Overview = ({ formik }) => {
  const { handleChange, handleBlur, values, errors, touched, handleSubmit } =
    formik;

  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto",
          rowGap: "16px",
          width: "100%",
          maxWidth: "600px",
        }}
      >
        <div>
          <LabelFieldComponent
            type="text"
            label="First Name"
            name="firstName"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.firstName ? errors.firstName : ""}
            labelDir="label-col"
            fieldSize="mb-4 w-100 h-md"
          />
          {formik.errors.firstName && formik.touched.firstName && (
            <ErrorMsg msg={formik.errors.firstName} />
          )}
        </div>

        <LabelFieldComponent
          type="text"
          label="Middle Name"
          name="middleName"
          value={values.middleName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.middleName ? errors.middleName : ""}
          labelDir="label-col"
          fieldSize="mb-4 w-100 h-md"
        />
        <div>
          <LabelFieldComponent
            type="text"
            label="Last Name"
            name="lastName"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.lastName ? errors.lastName : ""}
            labelDir="label-col"
            fieldSize="mb-4 w-100 h-md"
          />
          {formik.errors.lastName && formik.touched.lastName && (
            <ErrorMsg msg={formik.errors.lastName} />
          )}
        </div>

        <div>
          <LabelFieldComponent
            type="email"
            label="Email"
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email ? errors.email : ""}
            labelDir="label-col"
            fieldSize="mb-4 w-100 h-md"
          />
          {formik.errors.email && formik.touched.email && (
            <ErrorMsg msg={formik.errors.email} />
          )}
        </div>

        <div>
          <LabelFieldComponent
            type="text"
            label="Password"
            name="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.password ? errors.password : ""}
            labelDir="label-col"
            fieldSize="mb-4 w-100 h-md"
          />
          {formik.errors.password && formik.touched.password && (
            <ErrorMsg msg={formik.errors.password} />
          )}
        </div>

        <CountrySelect
          value={values.country}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.country ? errors.country : ""}
          touched={touched.country}
        />

        <ButtonComponent
          type="submit"
          className="mc-btn primary"
          style={{ height: "32px" }}
        >
          Save Changes
        </ButtonComponent>
      </div>
    </form>
  );
};

const ErrorMsg = ({ msg }) => {
  return <p className="d-flex text-danger">{msg}</p>;
};
