import React, { useContext } from "react"
import { TranslatorContext } from "../../../context/Translator"
import { Link } from "react-router-dom"
import { Row, Col, Dropdown } from "react-bootstrap"
import { FloatCardComponent } from "../../../components/cards"
import { PaginationComponent } from "../../../components"
import LabelFieldComponent from "../../../components/fields/LabelFieldComponent"
import InvoiceTableComponent from "../../../components/tables/InvoiceTableComponent"
import PageLayout from "../../../layouts/PageLayout"
import invoices from "../../../assets/data/invoices.json"
import traffics from "../../../assets/data/traffics.json"
import { ButtonComponent } from "../../../components/elements"
import { Button } from "bootstrap"

export default function CreateInvestor() {
  const { t, c } = useContext(TranslatorContext)

  const floats = [
    {
      title: "recieved_amount",
      digit: 78593,
      icon: "account_balance",
      variant: "lg green",
    },
    {
      title: "drafts_amount",
      digit: 24950,
      icon: "drafts",
      variant: "lg blue",
    },
    {
      title: "pending_amount",
      digit: 53617,
      icon: "pending",
      variant: "lg purple",
    },
  ]

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div className="mc-card">
            <div className="mc-breadcrumb">
              <h3 className="mc-breadcrumb-title">{t("Create Investor")}</h3>
              <ul className="mc-breadcrumb-list">
                <li className="mc-breadcrumb-item">
                  <Link to="/dashboard" className="mc-breadcrumb-link">
                    {t("home")}
                  </Link>
                </li>
                <li className="mc-breadcrumb-item">
                  <Link to="/investors" className="mc-breadcrumb-link">
                    {t("Investors")}
                  </Link>
                </li>

                <li className="mc-breadcrumb-item">
                  <Link to="/investors/create" className="mc-breadcrumb-link">
                    {t("create Investors")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Col>
        {/* {floats.map((float, index) => (
          <Col key={index} md={6} lg={4}>
            <FloatCardComponent
              variant={float.variant}
              digit={c(float.digit)}
              title={t(float.title)}
              icon={float.icon}
            />
          </Col>
        ))} */}
        <Col xl={12}>
          <div className="mc-card">
            <div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto",
                  rowGap: "0px",
                  width: "100%",
                  maxWidth: "600px",
                }}
              >
                <LabelFieldComponent
                  option={["Ankit", "Abhishek", "Aniket", "Suraj"]}
                  label={t("Select User")}
                  labelDir="label-col"
                  fieldSize="mb-4 w-100 h-md"
                />
                <LabelFieldComponent
                  option={[111890, 111990, 111234]}
                  label={t("Select Account")}
                  labelDir="label-col"
                  fieldSize="mb-4 w-100 h-md"
                />

                <LabelFieldComponent
                  option={[
                    "11879 - PROFIT:35 USD, DIRECTION: BOTH",
                    "11821 - PROFIT:-25 USD, DIRECTION: BOTH",
                    "11678 - PROFIT:15 USD, DIRECTION: BOTH",
                    "11119 - PROFIT:0.5 USD, DIRECTION: BOTH",
                  ]}
                  label={t("Select FUND MANAGER")}
                  labelDir="label-col"
                  fieldSize="mb-4 w-100 h-md"
                />
                <LabelFieldComponent
                  option={["BOTH", "BUY", "SELL"]}
                  label={t("Select Direction")}
                  labelDir="label-col"
                  fieldSize="mb-4 w-100 h-md"
                />

                <div>
                  <Link to="/investors/11849/edit">
                    <ButtonComponent
                      className="mc-btn primary"
                      style={{ height: "32px" }}
                    >
                      Create Investor
                    </ButtonComponent>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </PageLayout>
  )
}
