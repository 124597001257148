import React, { useContext } from "react"
import { TranslatorContext } from "../../context/Translator"
import { Link } from "react-router-dom"
import { Row, Col, Dropdown } from "react-bootstrap"
import { FloatCardComponent } from "../../components/cards"
import { PaginationComponent } from "../../components"
import LabelFieldComponent from "../../components/fields/LabelFieldComponent"
import InvoiceTableComponent from "../../components/tables/InvoiceTableComponent"
import PageLayout from "../../layouts/PageLayout"
import invoices from "../../assets/data/invoices.json"
import traffics from "../../assets/data/investors.json"
import FundManagerTableComponent from "../../components/tables/FundManagerTableComponent"
import InvestorTableComponent from "../../components/tables/InvestorTableComponent"

export default function InvestorsListScreen() {
  const { t, c, n } = useContext(TranslatorContext)

  const floats = [
    {
      title: "Total Investors",
      digit: traffics.tbody.length,
      icon: "account_balance",
      variant: "lg green",
    },
    {
      title: "Active Investors",
      digit: traffics.tbody.length - 1,
      icon: "drafts",
      variant: "lg blue",
    },
    {
      title: "Total Open Positions",
      digit: 33,
      icon: "pending",
      variant: "lg purple",
    },
  ]

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div className="mc-card">
            <div className="mc-breadcrumb">
              <h3 className="mc-breadcrumb-title">{t("Investors")}</h3>
              <ul className="mc-breadcrumb-list">
                <li className="mc-breadcrumb-item">
                  <Link to="/dashboard" className="mc-breadcrumb-link">
                    {t("home")}
                  </Link>
                </li>
                <li className="mc-breadcrumb-item">
                  <Link to="/investors" className="mc-breadcrumb-link">
                    {t("Investors")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Col>
        {floats.map((float, index) => (
          <Col key={index} md={6} lg={4}>
            <FloatCardComponent
              variant={float.variant}
              digit={n(float.digit)}
              title={t(float.title)}
              icon={float.icon}
            />
          </Col>
        ))}
        <Col xl={12}>
          <div className="mc-card">
            <div className="mc-card-header">
              <h4 className="mc-card-title"></h4>

              <div
                style={{
                  display: "inline-flex",
                  flexDirection: "row",
                  marginLeft: "auto",
                }}
              >
                <Link
                  to="/investors/create"
                  style={{
                    display: "inline-flex",
                    flexDirection: "row",
                    marginLeft: "auto",
                  }}
                >
                  <i className="material-icons">add</i> &nbsp; Create Investor
                </Link>
              </div>

              <Dropdown bsPrefix="mc-dropdown">
                <Dropdown.Toggle bsPrefix="mc-dropdown-toggle"></Dropdown.Toggle>
                <Dropdown.Menu align="end" className="mc-dropdown-paper">
                  <button type="button" className="mc-dropdown-menu">
                    <i className="material-icons">edit</i>
                    <span>{t("edit")}</span>
                  </button>
                  <button type="button" className="mc-dropdown-menu">
                    <i className="material-icons">delete</i>
                    <span>{t("delete")}</span>
                  </button>
                  <button type="button" className="mc-dropdown-menu">
                    <i className="material-icons">download</i>
                    <span>{t("download")}</span>
                  </button>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <Row xs={1} sm={2} lg={4}>
              <Col>
                <LabelFieldComponent
                  type="search"
                  label={t("search_by")}
                  labelDir="label-col"
                  fieldSize="mb-4 w-100 h-md"
                  placeholder={t("id") + " / " + t("name")}
                />
              </Col>

              <Col>
                <LabelFieldComponent
                  type="search"
                  label={t("search_by")}
                  labelDir="label-col"
                  fieldSize="mb-4 w-100 h-md"
                  placeholder={t("email")}
                />
              </Col>

              <Col>
                <LabelFieldComponent
                  label={t("status_by")}
                  option={["active", "inactive", "pending"]}
                  labelDir="label-col"
                  fieldSize="mb-4 w-100 h-md"
                />
              </Col>
              <Col>
                <LabelFieldComponent
                  label={t("show_by")}
                  option={["12 row", "24 row", "36 row"]}
                  labelDir="label-col"
                  fieldSize="mb-4 w-100 h-md"
                />
              </Col>
            </Row>
            <InvestorTableComponent
              thead={traffics.thead}
              tbody={traffics.tbody}
            />
            <PaginationComponent first={1} last={traffics?.tbody?.length} />
          </div>
        </Col>
      </Row>
    </PageLayout>
  )
}
