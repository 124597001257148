import * as React from "react"

import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query"

// CSS & SCSS
import "./assets/fonts/inter.css"
import "./assets/fonts/material.css"
import "./assets/fonts/icofont/icofont.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "./assets/sass/styles.scss"
import "react-toastify/dist/ReactToastify.css"

// JS & COMPONENTS
import "./i18n"
import { createRoot } from "react-dom/client"
import { ThemeProvider } from "./context/Themes"
import { SidebarProvider } from "./context/Sidebar"
import { LoaderProvider } from "./context/Preloader"
import { TranslatorProvider } from "./context/Translator"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { LoginPage, RegisterPage, ForgotPasswordPage } from "./pages/auth"
import {
  OverviewPage,
  DocumentationPage,
  ChangeLogPage,
  ErrorPage,
} from "./pages/others"
import {
  AvatarPage,
  AlertPage,
  ButtonPage,
  ChartPage,
  TablePage,
  FieldPage,
  HeadingPage,
  ColorPage,
} from "./pages/ui"
import {
  EcommercePage,
  AnalyticsPage,
  CRMPage,
  UserListPage,
  UserProfilePage,
  MyAccountPage,
  ProductListPage,
  ProductViewPage,
  ProductUploadPage,
  InvoiceListPage,
  InvoiceDetailsPage,
  OrderListPage,
  MessagePage,
  NotificationPage,
  BlankPage,
  SettingsPage,
} from "./pages/main"
import DashboardScreen from "./pages/screens/DashboardScreen"
import UsersListScreen from "./pages/screens/UsersListScreen"
import FundManagerListScreen from "./pages/screens/FundManagerListScreen"
import InvestorsListScreen from "./pages/screens/InvestorsListScreen"
import ReportsListScreen from "./pages/screens/ReportsListScreen"
import CreateUsersListScreen from "./pages/screens/Users/CreateUsersListScreen"
import EditUsersListScreen from "./pages/screens/Users/EditUsersListScreen"
import CreateFundmanager from "./pages/screens/Fundmanager/CreateFundmanager"
import EditFundManagerListScreen from "./pages/screens/Fundmanager/EditFundManagerScreen"
import CreateInvestor from "./pages/screens/Investor/CreateInvestor"
import EditInvestorsListScreen from "./pages/screens/Investor/EditInvestorsScreen"
import ProtectedRoute from "./lib/ProtectedRoute"
import { ToastContainer } from "react-toastify"
import MyAccountsListScreen from "./pages/screens/MyAccounts"
import EditAccountScreen from "./pages/screens/MyAccounts/EditAccountScreen"
import CreateFundmanagerScreen from "./pages/screens/MyAccounts/CreateFundManager"
import CreateInvestorScreen from "./pages/screens/MyAccounts/CreateInvestor"
import LeaderboardListScreen from "./pages/screens/LeaderBoard"
import CommissionsListScreen from "./pages/screens/CommissionListScreen"

const queryClient = new QueryClient()

const router = createBrowserRouter([
  // FINAL PATHS LOGIN
  { path: "/", element: <LoginPage /> },
  {
    path: "/dashboard",
    element: (
      <ProtectedRoute>
        <DashboardScreen />
      </ProtectedRoute>
    ),
  },
  {
    path: "/my-accounts",
    element: (
      <ProtectedRoute>
        <MyAccountsListScreen />
      </ProtectedRoute>
    ),
  },

  {
    path: "/my-accounts/:id",
    element: (
      <ProtectedRoute>
        <EditAccountScreen />
      </ProtectedRoute>
    ),
  },

  {
    path: "/leaderboard",
    element: (
      <ProtectedRoute>
        <LeaderboardListScreen />
      </ProtectedRoute>
    ),
  },

  { path: "/fees", element: <ReportsListScreen /> },
  { path: "/commissions", element: <CommissionsListScreen /> },

  {
    path: "/my-accounts/add-fundmanager",
    element: (
      <ProtectedRoute>
        <CreateFundmanagerScreen />
      </ProtectedRoute>
    ),
  },

  {
    path: "/my-accounts/add-investor",
    element: (
      <ProtectedRoute>
        <CreateInvestorScreen />
      </ProtectedRoute>
    ),
  },

  {
    path: "/users/create",
    element: (
      <ProtectedRoute>
        <CreateUsersListScreen />
      </ProtectedRoute>
    ),
  },
  {
    path: "/users/:id/edit",
    element: (
      <ProtectedRoute>
        <EditUsersListScreen />
      </ProtectedRoute>
    ),
  },
  {
    path: "/fund-managers",
    element: (
      <ProtectedRoute>
        <FundManagerListScreen />
      </ProtectedRoute>
    ),
  },
  { path: "/fund-managers/create", element: <CreateFundmanager /> },
  { path: "/fund-managers/:id/edit", element: <EditFundManagerListScreen /> },

  { path: "/investors", element: <InvestorsListScreen /> },
  { path: "/investors/create", element: <CreateInvestor /> },

  { path: "/investors/:id/edit", element: <EditInvestorsListScreen /> },

  { path: "/reports", element: <ReportsListScreen /> },

  //

  //UsersListScreen

  // MAIN PAGES
  { path: "/ecommerce", element: <EcommercePage /> },
  { path: "/analytics", element: <AnalyticsPage /> },
  { path: "/crm", element: <CRMPage /> },
  { path: "/forgot-password", element: <ForgotPasswordPage /> },
  { path: "/register", element: <RegisterPage /> },
  { path: "/login", element: <LoginPage /> },
  { path: "/user-list", element: <UserListPage /> },
  { path: "/user-profile", element: <UserProfilePage /> },
  { path: "/my-account", element: <MyAccountPage /> },
  { path: "/product-list", element: <ProductListPage /> },
  { path: "/product-view", element: <ProductViewPage /> },
  { path: "/product-upload", element: <ProductUploadPage /> },
  { path: "/invoice-list", element: <InvoiceListPage /> },
  { path: "/invoice-details", element: <InvoiceDetailsPage /> },
  { path: "/order-list", element: <OrderListPage /> },
  { path: "/message", element: <MessagePage /> },
  { path: "/notification", element: <NotificationPage /> },
  { path: "/blank-page", element: <BlankPage /> },
  { path: "/settings", element: <SettingsPage /> },

  // UI PAGES
  { path: "/avatars", element: <AvatarPage /> },
  { path: "/alerts", element: <AlertPage /> },
  { path: "/buttons", element: <ButtonPage /> },
  { path: "/charts", element: <ChartPage /> },
  { path: "/tables", element: <TablePage /> },
  { path: "/fields", element: <FieldPage /> },
  { path: "/headings", element: <HeadingPage /> },
  { path: "/colors", element: <ColorPage /> },

  // OTHER PAGES
  { path: "/", element: <OverviewPage /> },
  { path: "/documentation", element: <DocumentationPage /> },
  { path: "/changelog", element: <ChangeLogPage /> },
  { path: "/error", element: <ErrorPage /> },
])

createRoot(document.getElementById("root")).render(
  <ThemeProvider>
    <LoaderProvider>
      <TranslatorProvider>
        <QueryClientProvider client={queryClient}>
          <SidebarProvider>
            <RouterProvider router={router} />
            <ToastContainer
              enableMultiContainer
              containerId={"solid"}
              position="bottom-left"
              theme="colored"
              closeButton={false}
              autoClose={1000}
              style={{ minWidth: 340, fontSize: 14 }}
            />
          </SidebarProvider>
        </QueryClientProvider>
      </TranslatorProvider>
    </LoaderProvider>
  </ThemeProvider>
)
