import React from "react"
import HeaderLayout from "./HeaderLayout"
import SidebarLayout from "./SidebarLayout"
import MainLayout from "./MainLayout"
import FooterLayout from "./FooterLayout"
import { useLocation } from "react-router-dom"
import { useEffect } from "react"
import TopHeaderLayout from "./TopHeaderLayout"

export default function PageLayout({ children, title, breadcrumbs }) {
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <>
      <div
        style={{
          width: "100%",
          maxWidth: "1440px",
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        <TopHeaderLayout />

        <MainLayout>
          <>
            <PageDetails title="" breadcrumbs="" />

            <div style={{ width: "100%", minHeight: "calc(100vh - 120px)" }}>
              {children}
            </div>

            {pathname !== "/message" ? <FooterLayout /> : ""}
          </>
        </MainLayout>
      </div>
    </>
  )
}

const PageDetails = ({ title, breadcrumbs }) => {
  return <></>
}
