import React, { useContext, useMemo, useState } from "react"
import { TranslatorContext } from "../../../context/Translator"
import { Link } from "react-router-dom"
import { Row, Col, Dropdown } from "react-bootstrap"
import { PaginationComponent } from "../../../components"
import PageLayout from "../../../layouts/PageLayout"
import { useFundmanagersList } from "../../../lib/actions"
import DynamicTableComponent from "../../../components/tables/DynamicTableComponent"
import ListOfLeaders from "./ListOfLeaders"

export default function LeaderboardListScreen() {
  const { t, c, n } = useContext(TranslatorContext)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(8)
  const { isLoading, isError, data } = useFundmanagersList(page, limit)

  const IdCellRenderer = ({ index }) => {
    return index + 1
  }

  const userColumns = useMemo(
    () => [
      { Header: "ID", accessor: "id", Cell: IdCellRenderer },
      { Header: "Name", accessor: "name" },
      { Header: "Description", accessor: "description" },
      { Header: "Deposit", accessor: "minimunDeposit" },
      { Header: "Performance Fee", accessor: "performanceFee" },
      { Header: "Platfrom Fee", accessor: "platfromFee", Cell: PlatfromFeeCom },
    ],
    []
  )

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div className="mc-card">
            <div className="mc-breadcrumb">
              <h3 className="mc-breadcrumb-title">{t("Leaderboard")}</h3>
              <ul className="mc-breadcrumb-list">
                <li className="mc-breadcrumb-item">
                  <Link to="/dashboard" className="mc-breadcrumb-link">
                    {t("home")}
                  </Link>
                </li>
                <li className="mc-breadcrumb-item">
                  <Link to="/leader-board" className="mc-breadcrumb-link">
                    {t("leader board")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Col>

        <ListOfLeaders />
      </Row>
    </PageLayout>
  )
}

const PlatfromFeeCom = ({ row }) => {
  return <p>{row?.platfromFee ?? "NA"}</p>
}
