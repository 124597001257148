import React, { useMemo, useContext, useState } from "react"
import { TranslatorContext } from "../../context/Translator"
import Modal from "react-bootstrap/Modal"
import { ButtonComponent } from "../elements"
import { Spinner } from "react-bootstrap"
import _ from "lodash"

export default function DynamicTableComponent({
  columns,
  data,
  isLoading,
  isError,
}) {
  const [alertModal, setAlertModal] = useState(false)
  const { t } = useContext(TranslatorContext)

  const tableData = data

  return (
    <div className="mc-table-responsive">
      <table className="mc-table">
        <thead className="mc-table-head primary">
          <tr>
            {columns.map((column, index) => (
              <th key={index}>{column.Header}</th>
            ))}
          </tr>
        </thead>
        <tbody className="mc-table-body even">
          {isLoading ? (
            <>
              <tr>
                <td colSpan={columns?.length}>
                  <div style={{ minHeight: "200px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Spinner />
                    </div>
                  </div>
                </td>
              </tr>
            </>
          ) : isError || !tableData?.length ? (
            <>
              <tr>
                <td colSpan={columns?.length}>
                  <div style={{ textAlign: "center" }}>
                    No data available....
                  </div>
                </td>
              </tr>
            </>
          ) : (
            <>
              {tableData?.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {columns?.map((column, colIndex) => (
                    <td key={colIndex}>
                      {column?.Cell ? (
                        <column.Cell
                          value={row[column.accessor]}
                          row={row}
                          index={rowIndex}
                        />
                      ) : (
                        row[column.accessor]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>

      <Modal show={alertModal} onHide={() => setAlertModal(false)}>
        <div className="mc-alert-modal">
          <i className="material-icons">new_releases</i>
          <h3>Are you sure!</h3>
          <p>Want to delete this user?</p>
          <Modal.Footer>
            <ButtonComponent
              type="button"
              className="btn btn-secondary"
              onClick={() => setAlertModal(false)}
            >
              {t("close")}
            </ButtonComponent>
            <ButtonComponent
              type="button"
              className="btn btn-danger"
              onClick={() => setAlertModal(false)}
            >
              {t("delete")}
            </ButtonComponent>
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  )
}
