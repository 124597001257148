import React, { useContext, useEffect, useState } from "react";
import { TranslatorContext } from "../../context/Translator";
import { ButtonComponent, AnchorComponent } from "../../components/elements";
import IconFieldComponent from "../../components/fields/IconFieldComponent";
import LogoComponent from "../../components/LogoComponent";
import { useLogin } from "../../lib/actions";
import getErrorMessage, {
  emitErrorNotification,
  emitNotification,
} from "../../lib/helper";
import { useNavigate } from "react-router-dom";
import { checkTokenExpiry, storeToken } from "../../lib/tokenUtils";
import { useFormik } from "formik";
import { loginValidationSchema } from "../../lib/validationSchema";
import { LoginInitialValues as initialValues } from "../../lib/types";

export default function LoginPage() {
  const navigate = useNavigate();
  const { t } = useContext(TranslatorContext);

  const [loading, setLoading] = useState(false);

  const { mutate: loginMutation } = useLogin();

  const formik = useFormik({
    initialValues,
    validationSchema: loginValidationSchema,
    onSubmit: (values) => {
      setLoading(true);
      loginMutation(values, {
        onSuccess: (data) => {
          if (data.status === true && data?.data?.token) {
            storeToken(data?.data?.token, data?.data?.user?.type);
            emitNotification("success", "Login successful");
            navigate("/dashboard");
          } else {
            emitErrorNotification("Failed to login");
          }
          setLoading(false);
        },
        onError: (error) => {
          emitErrorNotification(getErrorMessage(error));
          setLoading(false);
        },
      });
    },
  });

  useEffect(() => {
    if (checkTokenExpiry()) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <div className="mc-auth">
      <img
        src="images/pattern.webp"
        alt="pattern"
        className="mc-auth-pattern"
      />
      <div className="mc-auth-group">
        <LogoComponent
          src="images/logo.webp"
          alt="logo"
          href="/ecommerce"
          className="mc-auth-logo"
        />
        <h4 className="mc-auth-title">Login to StrelaSoft</h4>
        <form className="mc-auth-form" onSubmit={formik.handleSubmit}>
          <div className="mb-3">
            <IconFieldComponent
              icon="email"
              type="email"
              name="email"
              classes="w-100 h-sm mb-0"
              placeholder={t("enter_your_email")}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.email && formik.touched.email && (
              <ErrorMsg msg={formik.errors.email} />
            )}
          </div>
          <div className="mb-3">
            <IconFieldComponent
              icon="lock"
              name="password"
              type="password"
              classes="w-100 h-sm mb-0"
              placeholder={t("enter_your_password")}
              passwordVisible={true}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.password && formik.touched.password && (
              <ErrorMsg msg={formik.errors.password} />
            )}
          </div>
          {/* <IconFieldComponent
            icon="verified_user"
            classes="w-100 h-sm"
            option={[
              "select user role",
              "admin",
              "member",
              "client",
              "manager",
              "vendor",
            ]}
          /> */}

          <div style={{ width: "100%" }}>
            <ButtonComponent
              style={{ width: "100%" }}
              className="mc-auth-btn h-sm"
              type="submit"
            >
              {loading ? "Loading..." : t("sign_in")}
            </ButtonComponent>
          </div>
          <AnchorComponent className="mc-auth-forgot" to="/register">
            {t("sign_up")}
          </AnchorComponent>
          {/* <div className="mc-auth-divide">
            <span>{t("or")}</span>
          </div>
          <div className="mc-auth-connect">
            <AnchorComponent to="#" className="twitter h-sm">
              <i className="icofont-twitter"></i>
              <span>Continue with Twitter</span>
            </AnchorComponent>
            <AnchorComponent to="#" className="facebook h-sm">
              <i className="icofont-facebook"></i>
              <span>Continue with Facebook</span>
            </AnchorComponent>
          </div> */}
        </form>
        {/* <div className="mc-auth-navigate">
          <span>Don't have an account?</span>
          <AnchorComponent to="/register">{t("register")}</AnchorComponent>
        </div> */}
      </div>
    </div>
  );
}

const ErrorMsg = ({ msg }) => {
  return <p className="d-flex text-danger">{msg}</p>;
};
