import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import { ButtonComponent } from "./elements";
import { TranslatorContext } from "../context/Translator";

function ConfirmModal({
  showModal,
  setShowModal,
  onConfirm,
  btnText = "Confirm",
  title="are your sure!",
  desc = "Want to delete this user?",
}) {
  const { t } = useContext(TranslatorContext);
  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <div className="mc-alert-modal">
          <i className="material-icons">new_releases</i>
          <h3>{title}</h3>
          <p>{desc}</p>
          <Modal.Footer>
            <ButtonComponent
              type="button"
              className="btn btn-secondary"
              onClick={() => setShowModal(false)}
            >
              {t("close")}
            </ButtonComponent>
            <ButtonComponent
              type="button"
              className="btn btn-danger"
              onClick={onConfirm}
            >
              {t(btnText)}
            </ButtonComponent>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}

export default ConfirmModal;
