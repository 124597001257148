import React, { useContext, useMemo, useState } from "react"
import { TranslatorContext } from "../../../context/Translator"
import { Link, useNavigate } from "react-router-dom"
import { Row, Col, Button } from "react-bootstrap"
import PageLayout from "../../../layouts/PageLayout"
import {
  useDeleteUser,
  useGetMyAccountsList,
  useGetUsersList,
} from "../../../lib/actions"
import { AnchorComponent, ButtonComponent } from "../../../components/elements"
import DynamicTableComponent from "../../../components/tables/DynamicTableComponent"
import { formatDate } from "../../../lib/helper"
import ConfirmModal from "../../../components/ConfirmModal"
import { PaginationComponent } from "../../../components"

const ActionsButton = ({ row, type, onToggleModal }) => (
  <div className="mc-table-action">
    <AnchorComponent
      title="Edit"
      to={`/my-accounts/${row?.id}`}
      className="material-icons edit"
    >
      edit
    </AnchorComponent>
  </div>
)

const IdCellRenderer = ({ index }) => {
  return index + 1
}

const CreatedAtComp = ({ row }) => {
  if (!row.createdAt) return ""

  return formatDate(row.createdAt)
}

export default function MyAccountsListScreen() {
  const { t } = useContext(TranslatorContext)
  const [confirmModal, setConfirmModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(30)
  const { isLoading, isError, data } = useGetMyAccountsList(page, limit)
  const navigate = useNavigate()

  const { mutate: deleteUser } = useDeleteUser()

  const userColumns = useMemo(
    () => [
      { Header: "ID", accessor: "id", Cell: IdCellRenderer },
      { Header: "Login", accessor: "login" },
      { Header: "Type", accessor: "type" },
      { Header: "Created At", accessor: "createdAt", Cell: CreatedAtComp },
      {
        Header: "Actions",
        accessor: "",
        Cell: ({ row }) => {
          const type = row?.type?.toLowerCase()

          return (
            <ActionsButton
              row={row}
              type={type}
              onToggleModal={() => {
                navigate(`/my-accounts/${row?.id}`)
              }}
            />
          )
        },
      },
    ],
    []
  )

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div className="mc-card">
            <div className="mc-breadcrumb">
              <h3 className="mc-breadcrumb-title">{t("My Accounts")}</h3>
              <ul className="mc-breadcrumb-list">
                <li className="mc-breadcrumb-item">
                  <Link to="/dashboard" className="mc-breadcrumb-link">
                    {t("home")}
                  </Link>
                </li>
                <li className="mc-breadcrumb-item">
                  <Link to="/my-accounts" className="mc-breadcrumb-link">
                    {t("My Accounts")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Col>

        <Col xl={12}>
          <div className="mc-card">
            <div style={{ textAlign: "right" }}>
              <Link to="./add-fundmanager">
                <button className="mc-btn primary outline">
                  Add FundManager
                </button>{" "}
              </Link>
              &emsp;
              <Link to="/leaderboard">
                <button className="mc-btn primary outline">Add Investor</button>{" "}
              </Link>
            </div>

            <br />

            <DynamicTableComponent
              isLoading={isLoading}
              isError={isError}
              columns={userColumns}
              data={data?.data?.acc ?? []}
            />
            {!isLoading && !isError && (
              <PaginationComponent
                totalItems={data?.data?.acc?.length ?? 0}
                totalPages={1}
                currentPage={page}
                onPageChange={(newPage) => setPage(newPage)}
                limit={limit}
              />
            )}
          </div>
        </Col>
      </Row>
    </PageLayout>
  )
}

const Loader = () => {
  return <></>
}
