import React, { useContext, useEffect, useMemo, useState } from "react"
import { TranslatorContext } from "../../../context/Translator"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { Row, Col, Dropdown, Tab, Tabs } from "react-bootstrap"
import PageLayout from "../../../layouts/PageLayout"
import { useGetMyAccountsDetails } from "../../../lib/actions"
import { FloatCardComponent } from "../../../components/cards"
import DynamicTableComponent from "../../../components/tables/DynamicTableComponent"
import { EditFundManagerForm } from "./EditFundManagerForm"
import UnSubscribeFundmanager from "./UnSubscribeFundmanager"
import EditInvestor from "./EditInvestor"
import { formatDate } from "../../../lib/helper"
import LoadingComponent from "../../../components/LoadingComponent"
import _ from "lodash"
import ErrorBoundary from "../../../components/ErrorBoundary"
import { UserTypeEnum } from "../../../constants/enum"

export default function EditAccountScreen() {
  const { id, login } = useParams()

  const { isLoading, isError, data } = useGetMyAccountsDetails(id)
  let [searchParams, setSearchParams] = useSearchParams()

  const navigate = useNavigate()

  const type = useMemo(() => {
    return data?.data?.account?.accType ?? undefined
  }, [data, isLoading, isError])

  return (
    <>
      <PageLayout>
        <Row>
          <Col sm={12}>
            <div className="mc-card">
              <div className="mc-breadcrumb">
                <h3 className="mc-breadcrumb-title">
                  Account # {searchParams.get("login") ?? id}
                </h3>

                <ul className="mc-breadcrumb-list">
                  <li className="mc-breadcrumb-item">
                    <Link to="/dashboard" className="mc-breadcrumb-link">
                      {"Dashboard"}
                    </Link>
                  </li>
                  <li className="mc-breadcrumb-item">
                    <Link to="/my-accounts" className="mc-breadcrumb-link">
                      {"My Accounts"}
                    </Link>
                  </li>
                  <li className="mc-breadcrumb-item">
                    <Link
                      to={`/my-accounts/${id}`}
                      className="mc-breadcrumb-link"
                    >
                      {id}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>

        {isLoading || !type ? (
          <Row>
            <Col xl={12}>
              <div className="mc-card">
                <LoadingComponent />
              </div>
            </Col>
          </Row>
        ) : (
          <ErrorBoundary>
            {type === UserTypeEnum.FUNDMANAGER ? (
              <Fundmanager
                data={data}
                isError={isError}
                isLoading={isLoading}
              />
            ) : (
              <Investor data={data} isError={isError} isLoading={isLoading} />
            )}
          </ErrorBoundary>
        )}
      </PageLayout>
    </>
  )
}

const Fundmanager = ({ data, isLoading, isError }) => {
  const investerColumns = useMemo(
    () => [
      { Header: "ID", accessor: "id", Cell: IdCellRenderer },
      { Header: "Login", accessor: "login" },
      { Header: "Name", accessor: "username", Cell: UserNameCell },
      { Header: "Direction", accessor: "direction" },
      { Header: "Status", accessor: "isActive" },
      { Header: "Floating Loss", accessor: "floatingLoss" },
      { Header: "Floating Profit", accessor: "floatingProfit" },
      { Header: "Type", accessor: "type" },
      { Header: "created At", accessor: "createdAt", Cell: CreatedAt },
    ],
    []
  )

  return (
    <>
      <Col xl={12}>
        <div className="mc-card">
          <Tabs defaultActiveKey="Edit" id="mc" className="mc-tabs">
            <Tab eventKey="Edit" title={"Edit"} className="mc-tabpane edit">
              <EditFundManagerForm data={data?.data?.account} />
            </Tab>
            &emsp;
            <Tab
              eventKey="Investors"
              title={"Investors"}
              className="mc-tabpane Investors"
            >
              <DynamicTableComponent
                isLoading={isLoading}
                isError={isError}
                columns={investerColumns}
                data={data?.data?.account?.investorsList ?? []}
              />
            </Tab>
          </Tabs>
        </div>
      </Col>
    </>
  )
}

const Investor = ({ data, isLoading, isError }) => {
  return (
    <>
      <Col xl={12}>
        <div className="mc-card">
          <Tabs defaultActiveKey="Edit" id="mc" className="mc-tabs">
            <Tab eventKey="Edit" title={"Edit"} className="mc-tabpane edit">
              <EditInvestor account={data?.data?.account} />
            </Tab>
            &emsp;
            <Tab
              eventKey="Fund Manager"
              title={"Fund Manager"}
              className="mc-tabpane FundManager"
            >
              <UnSubscribeFundmanager account={data?.data?.account} />
            </Tab>
          </Tabs>
        </div>
      </Col>
    </>
  )
}

function EditAccountComponent({ type, isError, data, isLoading }) {
  const { t, c, n } = useContext(TranslatorContext)
  let { id } = useParams()
  const isInvestor = type === "investor"

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(8)

  const fundManagerColumns = useMemo(
    () => [
      { Header: "ID", accessor: "id", Cell: IdCellRenderer },
      { Header: "Name", accessor: "name" },
      { Header: "Description", accessor: "description" },
      { Header: "Min Deposit", accessor: "minimunDeposit" },
      { Header: "performance Fee", accessor: "performanceFee" },
      { Header: "Platfrom Fee", accessor: "platfromFee", Cell: PlatfromFeeCom },
      { Header: "created At", accessor: "createdAt", Cell: CreatedAt },
    ],
    []
  )

  const investerColumns = []

  const fundmanagerArray = data?.data?.account?.fundmanager
    ? [data.data.account.fundmanager]
    : []

  // const tableData = isInvestor
  //   ? fundmanagerArray
  //   : data?.data?.account?.investorsList

  const tableData = []

  const floats = [
    {
      title: isInvestor ? "Total Fund Managers" : "Total Investors",
      digit: data?.data?.account?.investorCount || 0,
      icon: "account_balance",
      variant: "lg green",
    },
    {
      title: "Total Positions",
      digit: 0,
      icon: "drafts",
      variant: "lg blue",
    },
    {
      title: "Total Profit",
      digit: isInvestor
        ? data?.data?.account?.floatingProfit
        : data?.data?.account?.profitSharing || 0,
      icon: "pending",
      variant: "lg purple",
    },
  ]

  return (
    <>
      <Row>
        <Col xl={12}>
          <div className="mc-card">
            <div className="mc-breadcrumb">
              {data?.data?.account ? (
                <h3 className="mc-breadcrumb-title">
                  {isInvestor
                    ? `Login Id - ${data?.data?.account?.login}`
                    : `Account Number - ${data?.data?.account?.accountNumber}`}
                </h3>
              ) : (
                <>Loading...</>
              )}
              <ul className="mc-breadcrumb-list">
                <li className="mc-breadcrumb-item">
                  <Link to="/dashboard" className="mc-breadcrumb-link">
                    {t("Dashboard")}
                  </Link>
                </li>
                <li className="mc-breadcrumb-item">
                  <Link to="/my-accounts" className="mc-breadcrumb-link">
                    {t("My Accounts")}
                  </Link>
                </li>
                <li className="mc-breadcrumb-item">
                  <Link
                    to={`/my-accounts/${id}`}
                    className="mc-breadcrumb-link"
                  >
                    {id}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Col>
        {floats?.map((float, index) => (
          <Col key={index} md={6} lg={4}>
            <FloatCardComponent
              variant={float.variant}
              digit={n(float.digit)}
              title={t(float.title)}
              icon={float.icon}
            />
          </Col>
        ))}
      </Row>

      <Col xl={12}>
        <div className="mc-card">
          {!data?.data?.account ? (
            <>
              <LoadingComponent />
            </>
          ) : (
            <Tabs defaultActiveKey="Edit" id="mc" className="mc-tabs">
              <Tab
                eventKey="Edit"
                title={t("edit")}
                className="mc-tabpane edit"
              >
                {!isInvestor && data?.data?.account ? (
                  <EditFundManagerForm data={data} />
                ) : (
                  data?.data?.account && <EditInvestor data={data} />
                )}
              </Tab>{" "}
              &emsp;
              <Tab
                eventKey="Investors "
                title={t(!isInvestor ? "investors" : "fund-manager")}
                className="mc-tabpane profile"
              >
                {!isInvestor ? (
                  <DynamicTableComponent
                    isLoading={isLoading}
                    isError={isError}
                    columns={investerColumns}
                    data={data?.data ? tableData : []}
                  />
                ) : (
                  <>
                    <DynamicTableComponent
                      isLoading={isLoading}
                      isError={isError}
                      columns={fundManagerColumns}
                      data={data?.data ? tableData : []}
                    />
                  </>
                )}
              </Tab>
            </Tabs>
          )}
        </div>
      </Col>
    </>
  )
}

const IdCellRenderer = ({ index }) => {
  return index + 1
}

const PlatfromFeeCom = ({ row }) => {
  return <p>{row?.platfromFee ?? "NA"}</p>
}

const UserNameCell = ({ row }) => {
  return <p>{row?.username ?? "NA"}</p>
}

const CreatedAt = ({ row }) => {
  return <p>{formatDate(row?.createdAt) ?? "NA"}</p>
}
