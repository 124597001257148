import React, { useContext } from "react"
import { Link, useNavigate } from "react-router-dom"
import LabelFieldComponent from "../../../components/fields/LabelFieldComponent"

import { ButtonComponent } from "../../../components/elements"
import { useFormik } from "formik"
import * as Yup from "yup"
import { EditFundManagerService } from "../../../lib/actions"
import { emitErrorNotification, emitNotification } from "../../../lib/helper"

const CreateFMSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  description: Yup.string(),
  login: Yup.number().required("Required"),
  minimunDeposit: Yup.number().min(0).required("Required"),
  performanceFee: Yup.number()
    .min(0)
    .max(50, "Maximum % sharing can't be more than 50%")
    .required("Required"),
})

export const EditFundManagerForm = ({ account }) => {
  const formik = useFormik({
    initialValues: {
      name: account?.name ?? "",
      description: account?.description ?? "",
      login: account?.login,
      minimunDeposit: account?.minimunDeposit ?? 0,
      performanceFee: account?.performanceFee ?? 0,
    },
    validationSchema: CreateFMSchema,
    onSubmit: async (values, actions) => {
      console.log(values)

      actions.setSubmitting(true)
      try {
        const result = await EditFundManagerService(values)

        if (result?.data?.status) {
          emitNotification("success", "Fundmanager Account Updated")
        } else {
          emitErrorNotification(result?.data?.message)
        }
      } catch (error) {}

      actions.setSubmitting(false)
    },
  })

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto",
            rowGap: "24px",
            width: "100%",
            maxWidth: "600px",
          }}
        >
          <LabelFieldComponent
            type="text"
            label={"Avatar Name"}
            labelDir="label-col"
            fieldSize=" w-100 h-md"
            name="name"
            value={formik?.values?.name}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            errorMessage={
              formik?.touched?.name && formik?.errors?.name
                ? formik?.errors?.name
                : ""
            }
          />

          <LabelFieldComponent
            type="text"
            label={"Description"}
            labelDir="label-col"
            fieldSize=" w-100 h-md"
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            name="description"
            value={formik?.values?.description}
            errorMessage={
              formik?.touched?.description && formik?.errors?.description
                ? formik?.errors?.description
                : ""
            }
          />

          <LabelFieldComponent
            type="number"
            label={"Minimum Deposit"}
            labelDir="label-col"
            fieldSize="w-75 h-md"
            placeholder={""}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            name="minimunDeposit"
            value={formik?.values?.minimunDeposit}
            errorMessage={
              formik?.touched?.minimunDeposit && formik?.errors?.minimunDeposit
                ? formik?.errors?.minimunDeposit
                : ""
            }
          />

          <LabelFieldComponent
            type="text"
            label={"Profit Sharing (%)"}
            labelDir="label-col"
            fieldSize="w-75 h-md"
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            name="performanceFee"
            value={formik?.values?.performanceFee}
            errorMessage={
              formik?.touched?.performanceFee && formik?.errors?.performanceFee
                ? formik?.errors?.performanceFee
                : ""
            }
          />

          <div>
            <ButtonComponent
              className="mc-btn primary"
              style={{ height: "32px" }}
              type="submit"
            >
              Save
            </ButtonComponent>
            &emsp;
            <Link to="/my-accounts">
              <ButtonComponent
                className="mc-btn primary outline"
                style={{ height: "32px" }}
              >
                Cancel
              </ButtonComponent>
            </Link>
          </div>

          <div></div>
        </div>
      </form>
    </>
  )
}
